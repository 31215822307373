import React from 'react'
import { connect } from 'react-redux'
import { AmplifyGreetings } from '@aws-amplify/ui-react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Ring } from "react-awesome-spinners";
import { Dialog, DialogContent } from '@material-ui/core';

import Conference from './conference/Conference'
import UserMaintenance from './user/UserMaintenance'
import UserList from './user/UserList';
import CalendarSchedule from './calendar/CalendarSchedule'
import ConferenceAttendeeMaintenance from './user/ConferenceAttendeeMaintenance';
import '../style/calendar.css'
import logo from '../logo.png'


function AppContainer(props) {
    const username = 'name' in props.user.attributes ? props.user.attributes.name : props.user.attributes.email
    return (
        <Router>
            <AmplifyGreetings username={username}>
                <div slot="logo" className="logo">
                    <img src={logo} height="50" alt="Logo" style={{marginRight:10}}/>UAゼンセン千葉県支部会議出欠管理システム
                </div>
                <div slot="greetings-message">ようこそ、{username}</div>
            </AmplifyGreetings>
            <Switch>
                <Route path="/" component={CalendarSchedule} exact />
                <Route path="/conference" component={Conference} />
                <Route path="/conference?conferenceId=:conferenceId" component={Conference} />
                <Route path="/userMaintenance" component={UserMaintenance} />
                <Route path="/userList" component={UserList} />
                <Route path="/calendarSchedule" component={CalendarSchedule} />
                <Route path="/conferenceAttendeeMaintenance" component={ConferenceAttendeeMaintenance} />
            </Switch>
            <Dialog open={props.loading}><DialogContent><Ring/></DialogContent></Dialog>
        </Router>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        loading: state.loadingData.loading
    }
}

export default connect(mapStateToProps, null)(AppContainer)