import { SET_LOADING } from './loadingType'

const initialState = {
    loading: false,
}

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                loading: action.payload.info,
            }
        default:
            return state
    }
}

export default loadingReducer