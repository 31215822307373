import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import { setAuth } from '../../redux'
import { connect } from 'react-redux'

class MenuDialog extends Component {
  // 変更ボタンが押される
  handleEdit = () => {
    localStorage.setItem("date", this.props.selectEventData.eventDate);
    this.props.handleClose("edit", this.props.selectEventData.eventId);
  };

  // 削除ボタンが押される
  handleDelete = () => {
    this.props.handleClose("delete", this.props.selectEventData.eventId);
  };

  // 照会ボタンが押される
  handleSelect = () => {
    localStorage.setItem("date", this.props.selectEventData.eventDate);
    this.props.handleClose("select", this.props.selectEventData.eventId);
  };

  // キャンセルボタンが押される
  handleReturn = () => {
    this.props.handleClose("return");
  };

  render() {
    const adminFlag = 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0'
    return (
      <div>
        <Dialog open={this.props.open}>
          <DialogTitle className="dialog-title-style" style={{ width: "350px" }} />
          <DialogContent style={{ width: "350px" }}>
            <li className="dialog-body-li-name">{this.props.selectEventData.eventTitle} </li>
            <li className="dialog-body-li">
              <AccessTimeIcon className="dialog-body-li-icon" />
              <label className="dialog-body-li-time">
                {this.props.selectEventData.dialogDate}
              </label>
            </li>
          </DialogContent>
          <DialogActions className='dialog-actions-style'>
            {
              adminFlag === '1' || adminFlag === '2' ?
                <div>
                  <Button onClick={this.handleEdit} startIcon={<EditIcon fontSize="small" />}
                    color="primary" style={{ outline: "none" }} size="small">編集</Button>
                  <Button onClick={this.handleDelete} startIcon={<DeleteIcon fontSize="small" />}
                    color="secondary" style={{ outline: "none" }} size="small">削除</Button>
                  <Button onClick={this.handleReturn} startIcon={<CancelIcon fontSize="small" />}
                    color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                </div> :
                <div>
                  <Button onClick={this.handleSelect} startIcon={<SearchIcon fontSize="small" />}
                    color="primary" style={{ outline: "none" }} size="small">照会</Button>
                  <Button onClick={this.handleReturn} startIcon={<CancelIcon fontSize="small" />}
                    color="primary" style={{ outline: "none" }} size="small">キャンセル
                  </Button>
                </div>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.userData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (authData, userData) => dispatch(setAuth(authData, userData))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuDialog))