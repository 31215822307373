import { SET_MEETING, SET_MATERIAL, SET_USER_MATERIAL } from './mettingType'

export const setMeeting = (infoData) => {
    return {
        type: SET_MEETING,
        payload: {
            info: infoData,
        }
    }
}

export const setMaterial = (materials) => {
    return {
        type: SET_MATERIAL,
        payload: {
            info: materials,
        }
    }
}

export const setUserMaterial = (userMaterials) => {
    return {
        type: SET_USER_MATERIAL,
        payload: {
            info: userMaterials,
        }
    }
}