import React, { Component } from 'react';
import { setAuth } from '../../redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

class DeleteDialog extends Component {
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle className="dialog-title-style"></DialogTitle>
                <DialogContent><DialogContentText>削除しますか？</DialogContentText></DialogContent>
                <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                    <Button color="secondary" onClick={this.props.handleDelete} style={{ outline: "none" }} size="small">削除</Button>
                    <Button onClick={this.props.handleClose} color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.userData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (authData, userData) => dispatch(setAuth(authData, userData))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteDialog))