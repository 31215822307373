import { SET_MEETING, SET_MATERIAL, SET_USER_MATERIAL } from './mettingType'

const initialState = {
    info: {},
    materials: [],
    userMaterials: []
}

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MEETING:
            return {
                ...state,
                info: action.payload.info,
            }
        case SET_MATERIAL:
            return {
                ...state,
                materials: action.payload.info,
            }
        case SET_USER_MATERIAL:
            return {
                ...state,
                userMaterials: action.payload.info,
            }
        default:
            return state
    }
}

export default meetingReducer