import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ConferenceDetail from './ConferenceDetail'
import ConferenceDocument from './ConferenceDocument'
import ConferenceUserDocument from './ConferenceUserDocument'
import { Button, Paper, Container, Grid, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl } from '@material-ui/core';
import { API } from 'aws-amplify'
import '../../style/conference.css'
import '../../style/calendar.css'
import { connect } from 'react-redux'

class Kaigi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // ダイアログの状態
            open: false,
            shiryouRows: [],
            naiyouInfo: {},
            naiyouRows: [],
            conferenceId: '',
            selectedOption: '',
            selectedOptionBlank: false
        }
        this.handleClick = this.handleClick.bind(this)

        this.deleteEvent = this.deleteEvent.bind(this)
    }
    
    // 画面初期化
    async UNSAFE_componentWillMount() {
        let location = this.props.location.pathname + this.props.location.search
        let search = location.indexOf('?') === -1 ? '' : location.substr(location.indexOf('?') + 1)
        if (search !== '') {
            let conferenceId = search.substr(search.search("=") + 1)
            this.setState({conferenceId: conferenceId})
        }
    }

    async handleClick() {
        const naiyouData = this.handeleGetInfoNaiyou()
        if (naiyouData.title === ""
            || naiyouData.startTime === undefined
            || naiyouData.endTime === undefined
            || naiyouData.room === ""
            || this.state.selectedOption === "") {

            if (naiyouData.title === "") {
                this.naiyouChild.validateFalse("title")
            }

            if (naiyouData.startTime === undefined) {
                this.naiyouChild.validateFalse("sTime")
            }

            if (naiyouData.endTime === undefined) {
                this.naiyouChild.validateFalse("eTime")
            }

            if (naiyouData.room === "") {
                this.naiyouChild.validateFalse("room")
            }
            
            if (this.state.selectedOption === "") {
                this.setState({selectedOptionBlank: true })
            } else {
                this.setState({ selectedOptionBlank: false })
            }
            alert("必須項目が記入されていない")
            return
        }

        if (!naiyouData.timeCheck) {
            alert("会議の終了時間は開始時間より大きくなければならない")
            return
        }
        const attendees = naiyouData.rowsData.map(obj => {
            return Object.assign({}, {
                'username': obj.key,
                ...obj
            })
        })
        const materials = this.props.materials.map(obj => {
            return Object.assign({}, {
                'filekey': obj.key,
            })
        })
        // DataLogin
        if (this.isCreate()) {
            await API.post('uamrsapi', '/conference', {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    "operation": "createConference",
                    "operator": this.props.auth.user.username,
                    "title": naiyouData.title,
                    "date": naiyouData.date,
                    "starttime": naiyouData.startTime,
                    "endtime": naiyouData.endTime,
                    "attendees": attendees,
                    "room": naiyouData.room,
                    "webconference_info": naiyouData.webconferenceInfo,
                    "webconference_remark": naiyouData.webconferenceRemark,
                    "section_enable": naiyouData.section_enable,
                    "section": naiyouData.section,
                    "section2_enable": naiyouData.section2_enable,
                    "section2": naiyouData.section2,
                    "section3_enable": naiyouData.section3_enable,
                    "section3": naiyouData.section3,
                    "section4_enable": naiyouData.section4_enable,
                    "section4": naiyouData.section4,
                    "section5_enable": naiyouData.section5_enable,
                    "section5": naiyouData.section5,
                    "section6_enable": naiyouData.section6_enable,
                    "section6": naiyouData.section6,
                    "section7_enable": naiyouData.section7_enable,
                    "section7": naiyouData.section7,
                    "section8_enable": naiyouData.section8_enable,
                    "section8": naiyouData.section8,
                    "section9_enable": naiyouData.section9_enable,
                    "section9": naiyouData.section9,
                    "section10_enable": naiyouData.section10_enable,
                    "section10": naiyouData.section10,
                    "materials": materials,
                    "option": this.state.selectedOption
                },
                response: true,
            }).then((response) => {
                // pass
            }).catch((error) => {
                console.log(error)
            })
        } else {
            // DataUpdate
            await API.post('uamrsapi', '/conference', {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    "operation": "updateConference",
                    "operator": this.props.auth.user.username,
                    "conferenceId": this.state.conferenceId,
                    "title": naiyouData.title,
                    "date": naiyouData.date,
                    "starttime": naiyouData.startTime,
                    "endtime": naiyouData.endTime,
                    "attendees": attendees,
                    "room": naiyouData.room,
                    "webconference_info": naiyouData.webconferenceInfo,
                    "webconference_remark": naiyouData.webconferenceRemark,
                    "section_enable": naiyouData.section_enable,
                    "section": naiyouData.section,
                    "section2_enable": naiyouData.section2_enable,
                    "section2": naiyouData.section2,
                    "section3_enable": naiyouData.section3_enable,
                    "section3": naiyouData.section3,
                    "section4_enable": naiyouData.section4_enable,
                    "section4": naiyouData.section4,
                    "section5_enable": naiyouData.section5_enable,
                    "section5": naiyouData.section5,
                    "section6_enable": naiyouData.section6_enable,
                    "section6": naiyouData.section6,
                    "section7_enable": naiyouData.section7_enable,
                    "section7": naiyouData.section7,
                    "section8_enable": naiyouData.section8_enable,
                    "section8": naiyouData.section8,
                    "section9_enable": naiyouData.section9_enable,
                    "section9": naiyouData.section9,
                    "section10_enable": naiyouData.section10_enable,
                    "section10": naiyouData.section10,
                    "materials": materials,
                    "option": this.state.selectedOption
                },
                response: true,
            }).then((response) => {
                // pass
            }).catch((error) => {
                console.log(error)
            })
        }
        this.props.history.push("/calendarSchedule")
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = (ev, type) => {
        this.setState({
            open: false
        })
        if (type === "delete") {
            if (this.state.conferenceId !== undefined) {
                this.deleteEvent(this.state.conferenceId)
                return
            }
            this.props.history.push("/calendarSchedule")
        }
        if (type === "cancel") {
            this.props.history.push("/calendarSchedule")
        }
    };

    returnSchedule = () => {
        this.props.history.push("/calendarSchedule")
    }

    async deleteEvent(conferenceId) {
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "deleteConference",
                "operator": this.props.auth.user.username,
                "conferenceId": conferenceId
            },
            response: true,
        }).then((response) => {
            // pass
        }).catch((error) => {
            console.log(error)
        })
        this.props.history.push("/calendarSchedule")

    }

    onNaiyouRef = (ref) => { this.naiyouChild = ref };

    handeleGetInfoNaiyou = () => {
        return this.naiyouChild.handeleGetInfoNaiyou()
    };

    isCreate = () => {
        return this.state.conferenceId === '' || this.props.infoData === null
    }

    render() {
        const adminFlag = 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0'
        return (
            <Paper elevation={0} className="body-init" style={{width: "1000px" }}>
                {/* <div className="body-init"> */}
                <Container>
                    <Grid>
                        <Grid item lg={12} xs={12}>
                            <ConferenceDetail conferenceId = {this.state.conferenceId} onNaiyouRef={this.onNaiyouRef}/>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <ConferenceDocument conferenceId = {this.state.conferenceId}/>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <ConferenceUserDocument conferenceId = {this.state.conferenceId}/>
                        </Grid>
                        <Grid>
                            <div>
                                <Container style={{ margin: "70px 0px 40px" }}>
                                    <h4>登録・更新内容（必須）</h4>
                                    <Divider />
                                    <Grid container className="grid-top">
                                        <FormControl>
                                            <Select
                                                id="select-list"
                                                className="dropdown-select"
                                                onChange={(event) => {this.setState({selectedOption: event.target.value}) }}
                                                value={this.state.selectedOption}
                                                displayEmpty
                                                style={this.state.selectedOptionBlank ? { border: "1px solid red" } : { border: "1px solid #1976D2" }}
                                            >
                                                <MenuItem value="" disabled>選択してください</MenuItem>
                                                <MenuItem value="1">【開催内容】</MenuItem>
                                                <MenuItem value="2">【議事録アップ】</MenuItem>
                                                <MenuItem value="3">【振込明細アップ】</MenuItem>
                                                <MenuItem value="4">【修正】</MenuItem>
                                                <MenuItem value="5">【延期】</MenuItem>
                                                <MenuItem value="6">【中止】</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>   
                                </Container>
                            </div>
                        </Grid>
                        <Grid item lg={12} xs={12} className="foot-margin-bottom">
                            <div style={{ textAlign: "center" }}>
                                <Divider style={{ marginBottom: "20px" }} />
                                {
                                    this.isCreate() ?
                                    <>
                                        <Button variant="outlined" style={{ outline: "none", color: "#1976D2", fontWeight: "bold" }} className="foot-style" size="large"
                                            onClick={this.handleClick}>{'登録'}</Button>
                                        <Button variant="outlined" style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "40px" }}
                                            onClick={this.handleClickOpen} size="large" >{'破棄'}</Button>
                                    </>
                                    : (
                                        this.props.infoData && (adminFlag === '1' || (adminFlag === '2' && this.props.attendeesExists)) ?
                                            <>
                                                <Button variant="outlined" style={{ outline: "none", color: "#1976D2", fontWeight: "bold" }} className="foot-style"
                                                    size="large" onClick={this.handleClick}>{'更新'}</Button>
                                                <Button variant="outlined" style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "40px" }}
                                                    onClick={this.handleClickOpen} size="large" >{'削除'}</Button>
                                                <Button variant="outlined" style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "40px" }}
                                                    onClick={this.returnSchedule} size="large" >{'閉じる'}</Button>
                                            </> : 
                                            <Button variant="outlined" style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "40px" }}
                                                onClick={this.returnSchedule} size="large" >{'閉じる'}</Button>
                                    )
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle className="dialog-title-style"></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.isCreate() ? '破棄しますか？' : '削除しますか？'}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                            {this.isCreate() ?
                                <Button color="secondary" onClick={(ev) => this.handleClose(ev, "cancel")} style={{ outline: "none" }} size="small">破棄</Button>
                                : <Button color="secondary" onClick={(ev) => this.handleClose(ev, "delete")} style={{ outline: "none" }} size="small">削除</Button>}
                                <Button onClick={this.handleClose} color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Container>
                {/* </div> */}
            </Paper>
        )
    }
}

const mapStateToProps = (state) => {
    let attendees = state.infoData.info.attendees
    let attendeesExists = true
    if(attendees) {
        attendeesExists = attendees.map(obj => obj.username).includes(state.auth.user.username)
    }
    return {
        auth: state.auth,
        userData: state.userData,
        infoData: state.infoData,
        materials: state.infoData.materials,
        attendeesExists: attendeesExists
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Kaigi))