import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Table } from 'antd';
import { API } from 'aws-amplify'
import { setAuth, setLoading } from '../../redux'
import { connect } from 'react-redux'
import { Container, Grid, Paper, Divider, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import 'antd/dist/antd.css';
import '../../style/conference.css'
import '../../style/calendar.css'

class UserHaifu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            curUserListKey: '',
            curUserListEditFlag: false,
            curUserListName: '',
            userListDatas: [],
            userDatas: [],
            userEditedDatas: [],
            userInfoDilaogOpen: false,
            userInfoDatas: [],
            paginationToken:'',
            isBottom: false,
            userInfoSearchKey: '',
            userInfoKeys: [],
            selectedUserInfoNames: [],
            deleteOpen: false,
            cancelOpen: false,
            newUserListRecord: undefined,
            newUserListEditFlag: false,
            newUserListAddFlag: false,
        }

        this.scrollRef = React.createRef()
    }

    componentDidMount() {
        let adminFlag = 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0'
        if (adminFlag !== '1' && adminFlag !== '2') {
            this.props.history.push('/calendarSchedule')
        } else {
            this.handleGetUserList()
        }
    }

    async handleGetUserList() {
        let newUserListDatas = []
        let newCurUserListKey = '0'
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              'operation': 'getUserList'
            }
        }).then((response) => {
            let data = response[0]
            if (data.userList && data.userList.length > 0) {
                for(let i = 0; i < data.userList.length; i++) {
                    newUserListDatas.push({
                        key: data.userList[i].listid,
                        listname: data.userList[i].listname
                    })
                    if (i === 0) {
                        newCurUserListKey = data.userList[i].listid
                    }
                }
            }
            this.setState({
                curUserListKey: newCurUserListKey,
                userListDatas: newUserListDatas,
            }, () => {
                this.handleGetUsersInCurUserList(false)
            })
        }).catch((error) => {
            console.log(error)
            this.props.setLoading(false)
        })
    }

    handleAddUserList = () => {
        if(this.state.userEditedDatas.length > 0) {
            this.setState({
                cancelOpen: true,
                newUserListAddFlag: true,
            })
        } else {
            this.handleAddUserListExec()
        }
    }

    handleAddUserListExec = () => {
        let newUserListDatas = []
        newUserListDatas = newUserListDatas.concat(this.state.userListDatas)
        let tempKey = 'tmp' + new Date().valueOf()
        newUserListDatas.push({key: tempKey, })
        this.setState({
            curUserListKey: tempKey,
            curUserListName: '',
            userListDatas: newUserListDatas,
            userDatas: [],
            curUserListEditFlag: true,
            newUserListAddFlag: false
        })
    }

    handleEditUserList = (record) => {
        if(record.key === this.state.curUserListKey) {
            this.setState({
                curUserListKey: record.key,
                curUserListName: record.listname,
                curUserListEditFlag: true,
            })
        } else {
            if(this.state.userEditedDatas.length > 0) {
                this.setState({
                    cancelOpen: true,
                    newUserListRecord: record,
                    newUserListEditFlag: true
                })
            } else {
                this.setState({
                    newUserListRecord: record, 
                }, () => {
                    this.handleEditUserListExec()
                })
            }
        }
    }

    handleEditUserListExec = () => {
        let record = this.state.newUserListRecord
        if(!this.state.curUserListEditFlag) {
            this.setState({
                curUserListKey: record.key,
                curUserListName: record.listname,
            }, () => {
                this.handleGetUsersInCurUserList(true)
            })
        }
    }

    async handleConfirmEditUserList() {
        let newUserListDatas = []
        let newUserListKey = undefined
        if (this.state.curUserListName) {
            this.props.setLoading(true)
            await API.post('uamrsapi', '/conference', {
                headers: {
                  'Content-Type': 'application/json'
                },
                body: {
                  'operation': 'confirmEditUserList',
                  'operator': this.props.auth.user.username,
                  'listid': this.state.curUserListKey,
                  'listname': this.state.curUserListName,
                },
                response: true,
            }).then((response) => {
                let data = response.data
                let userListDatas = this.state.userListDatas
                let oldKeys = userListDatas.map(userList =>userList.key)
                if (data && data.length > 0) {
                    for(let i = 0; i < data[0].userList.length; i++) {
                        newUserListDatas.push({
                            key: data[0].userList[i].listid,
                            listname: data[0].userList[i].listname
                        })
                        if(!oldKeys.includes(data[0].userList[i].listid)) {
                            newUserListKey = data[0].userList[i].listid
                        }
                    }
                }
                this.setState({
                    userListDatas: newUserListDatas,
                    curUserListEditFlag: false,
                })
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.props.setLoading(false)
                this.setState({
                    newUserListRecord: undefined
                }, () => {
                    if(this.state.curUserListKey !== newUserListKey && newUserListKey) {
                        this.setState({
                            curUserListKey: newUserListKey
                        }, () => {
                            this.handleGetUsersInCurUserList(false)
                        })
                    }
                })
            })
        } else {
            newUserListDatas = [...this.state.userListDatas]
            let curUserListIndex
            let newUserListKey
            for(let index in newUserListDatas) {
                if(this.state.curUserListKey === newUserListDatas[index].key && !newUserListDatas[index].listname) {
                    curUserListIndex = index
                }
                if(newUserListDatas[index].listname && !newUserListKey) {
                    newUserListKey = newUserListDatas[index].key
                }
            }
            console.log(newUserListDatas)
            console.log(curUserListIndex)
            if(newUserListDatas[curUserListIndex].key.startsWith('tmp')) {
                newUserListDatas.splice(curUserListIndex, 1)
            } else {
                newUserListDatas[curUserListIndex].listname = this.state.newUserListRecord.listname
                newUserListKey = newUserListDatas[curUserListIndex].key
            }
            this.setState({
                userListDatas: newUserListDatas,
                curUserListEditFlag: false,
                curUserListKey: newUserListKey,
                newUserListRecord: undefined
            })
        }
    }

    handleCancelClose = () => {
        this.setState({
            cancelOpen: false,
            newUserListRecord: undefined,
            newUserListEditFlag: false,
            newUserListAddFlag: false
        })
    }

    handleConfirmCancel = () => {
        let newUserListEditFlag = this.state.newUserListEditFlag
        let newUserListAddFlag = this.state.newUserListAddFlag
        if (newUserListAddFlag) {
            this.setState({
                cancelOpen: false,
                userEditedDatas: [],
                newUserListAddFlag: false
            }, () => {
                this.handleAddUserListExec()
            })
        } else {
            this.setState({
                cancelOpen: false,
                curUserListKey: this.state.newUserListRecord.key,
                curUserListName: this.state.newUserListRecord.listname,
                userEditedDatas: [],
                newUserListRecord: undefined,
                newUserListEditFlag: false
            }, () => {
                this.handleGetUsersInCurUserList(newUserListEditFlag)
            })
        }
    }

    handleDeleteUserList = (record) => {
        if(!this.state.curUserListEditFlag) {
            this.setState({
                deleteOpen: true,
                newUserListRecord: record,
            })
        }
    }

    handleDeleteClose = () => {
        this.setState({
            deleteOpen: false,
            newUserListRecord: undefined,
        })
    }

    handleConfirmDelete = () => {
        this.setState({
            deleteOpen: false,
        }, () =>{
            this.handleDeleteUserListExec()
        })
    }

    async handleDeleteUserListExec() {
        let record = this.state.newUserListRecord
        let newUserListDatas = []
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              'operation': 'deleteUserList',
              'operator': this.props.auth.user.username,
              'listid': record.key,
            },
            response: true,
        }).then((response) => {
            let data = response.data
            let newUserListKey
            if (data && data.length > 0) {
                for(let i = 0; i < data[0].userList.length; i++) {
                    if(i === 0) {
                        newUserListKey = data[0].userList[i].listid
                    }
                    if(this.state.curUserListKey === data[0].userList[i].listid) {
                        newUserListKey = data[0].userList[i].listid
                    }
                    newUserListDatas.push({
                        key: data[0].userList[i].listid,
                        listname: data[0].userList[i].listname
                    })
                }
            }
            this.setState({
                userListDatas: newUserListDatas,
                newUserListRecord: undefined
            }, () => {
                if(this.state.curUserListKey !== newUserListKey && newUserListKey) {
                    this.setState({
                        curUserListKey: newUserListKey
                    }, () => {
                        this.handleGetUsersInCurUserList(false)
                    })
                }
            })
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    handleOpenUserInfoDialog = () => {
        if(this.state.userInfoDatas.length === 0) {
            this.handleGetUserInfo()
        }
        this.setState({
            userInfoDilaogOpen: true
        })
    }

    async handleGetUserInfo() {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'getUsers',
                'filter': {
                    'name': 'name',
                    'value': this.state.userInfoSearchKey
                }
            },
            response: true,
        }).then((response) => {
            if (response.data && response.data.length > 0) {
                let data = response.data[0].users
                let datas = []
                for (let i = 0; i < data.length; i++) {
                    datas.push(
                        {
                            key: data[i].username,
                            name: data[i].name,
                            authority: data[i].level,
                            companySector: data[i].department,
                            mail: data[i].email,
                            position: data[i].position
                        }
                    )
                }
                this.setState({
                    userInfoDatas: datas,
                    paginationToken: response.data[0].paginationToken
                }, () => {
                    if (this.state.paginationToken === undefined) {
                        this.setState({
                            isBottom: true
                        })
                    }
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.props.setLoading(false)
        })
    }

    async handleGetNextUserInfo() {
        if (this.state.isBottom) {
            return
        }
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'getUsers',
                'paginationToken': this.state.paginationToken
            },
            response: true,
        }).then((response) => {
            if (response.data && response.data.length > 0) {
                let data = response.data[0].users
                let datas = []
                for (let i = 0; i < data.length; i++) {
                    datas.push(
                        {
                            key: data[i].username,
                            name: data[i].name,
                            authority: data[i].level,
                            companySector: data[i].department,
                            mail: data[i].email,
                            position: data[i].position
                        }
                    )
                }
                datas = this.state.userInfoDatas.concat(datas)
                this.setState({
                    userInfoDatas: datas,
                    paginationToken: response.data[0].paginationToken
                }, () => {
                    if (this.state.paginationToken === undefined) {
                        this.setState({
                            isBottom: true
                        })
                    }
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.props.setLoading(false)
        })
    }

    handleUserInfoDialogScroll = () => {
        // eslint-disable-next-line
        const scrollTop = this.scrollRef.scrollTop;
        // eslint-disable-next-line
        const clientHeight = this.scrollRef.clientHeight;
        // eslint-disable-next-line
        const scrollHeight = this.scrollRef.scrollHeight;
        const isBottom = Math.ceil(scrollTop) + clientHeight === scrollHeight;
        if (isBottom) {
            this.handleGetNextUserInfo()
        }
    }

    handleUserInfoSelectChange = (userInfoKeys) => {
        let newSelectedUserInfoNames = []
        let userInfoDatas = this.state.userInfoDatas
        for (let i = 0; i < userInfoDatas.length; i ++) {
            if(userInfoKeys.includes(userInfoDatas[i].key)) {
                newSelectedUserInfoNames.push(userInfoDatas[i].name)
            }
        }
        this.setState({
            userInfoKeys, 
            selectedUserInfoNames: newSelectedUserInfoNames
        })
    }

    handleUserInfoDialogAdd = () => {
        let newUserDatas = [...this.state.userDatas]
        let newUserDatasKeys = newUserDatas.map(obj => obj.key)
        let newUserEditedDatas = [...this.state.userEditedDatas]
        let userInfoDatas = this.state.userInfoDatas
        for (let i = 0; i < userInfoDatas.length; i ++) {
            if(this.state.userInfoKeys.includes(userInfoDatas[i].key) && !newUserDatasKeys.includes(userInfoDatas[i].key)) {
                let tempUserData = userInfoDatas[i]
                tempUserData.editFlag = 'add'
                newUserDatas.push(tempUserData)
                newUserEditedDatas.push(tempUserData)
            }
        }
        this.setState({
            userDatas: newUserDatas,
            userEditedDatas: newUserEditedDatas,
            userInfoDilaogOpen: false,
            userInfoKeys: [],
            selectedUserInfoNames: [],
        })
    }

    handleBackEditedUserInCurUserList = (record) => {
        let newUserDatas = [...this.state.userDatas]
        let index = newUserDatas.indexOf(record)
        if(record.editFlag === 'add') {
            newUserDatas.splice(index, 1)
        } else if (record.editFlag === 'delete') {
            let { editFlag, ...newRecord } = record
            newUserDatas[index] = newRecord
        }
        let newUserEditedDatas = [...this.state.userEditedDatas]
        let indexEditedDatas = newUserEditedDatas.indexOf(record)
        newUserEditedDatas.splice(indexEditedDatas, 1)
        this.setState({
            userDatas: newUserDatas,
            userEditedDatas: newUserEditedDatas,
        })
    }

    async handleGetUsersInCurUserList(editFlag) {
        let newUserDatas = []
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              'operation': 'getUsersByUserListid',
              'listid': this.state.curUserListKey,
            }
        }).then((response) => {
            if (response && response.length > 0) {
                let data = response[0].users
                for (let index in data) {
                    newUserDatas.push({
                        key: data[index].username,
                        name: data[index].name,
                        companySector: data[index].department,
                        position: data[index].position
                    })
                }
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.props.setLoading(false)
            this.setState({
                userDatas: newUserDatas,
                userEditedDatas: [],
                curUserListEditFlag: editFlag
            })
        })
    }

    handleDeleteUserInCurUserList = (record) => {
        let newUserDatas = [...this.state.userDatas]
        let newUserEditedDatas = [...this.state.userEditedDatas]
        let index = newUserDatas.indexOf(record)
        let newUserData = record
        newUserData.editFlag = 'delete'
        newUserDatas[index] = newUserData
        newUserEditedDatas.push(newUserData)
        this.setState({
            userDatas: newUserDatas,
            userEditedDatas: newUserEditedDatas,
        })
    }

    async handleConfirmEditedUsersInCurUserList () {
        let users = []
        let userEditedDatas = this.state.userEditedDatas
        let newUserDatas = []
        let newUserEditedDatas = [...this.state.userEditedDatas]
        this.props.setLoading(true)
        for (let index in userEditedDatas) {
            users.push({'username': userEditedDatas[index].key, 'editFlag': userEditedDatas[index].editFlag})
        }
        await API.post('uamrsapi', '/conference', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              'operation': 'confirmEditedUserListDetail',
              'operator': this.props.auth.user.username,
              'listid': this.state.curUserListKey,
              'users': users
            }
        }).then((response) => {
            if (response && response.length > 0) {
                let data = response[0].users
                for (let index in data) {
                    newUserDatas.push({
                        key: data[index].username,
                        name: data[index].name,
                        companySector: data[index].department,
                        position: data[index].position
                    })
                }
            }
            newUserEditedDatas = []
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.props.setLoading(false)
            this.setState({
                userDatas: newUserDatas,
                userEditedDatas: newUserEditedDatas
            })
        })
    }
    
    async handleOutputAllUserListsWithUsers() {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "outputAllUserListsWithUsers",
                "operator": this.props.auth.user.username,
            },
            response: true,
        }).then((response) => {
            const byteCharacters = atob(response.data[0].excel);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const filename="配布リスト.xlsx"
            if (typeof window.navigator.msSaveBlob != 'undefined') {
                window.navigator.msSaveBlob(blob, filename)
            } else {
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a')
                link.href = objectUrl
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    render() {
        const columnsDistribute = [
            { title: '配布リスト名', dataIndex: 'listname', width: '180px',
                render: (text, record, index) => (
                    this.state.curUserListKey === record.key && this.state.curUserListEditFlag ? 
                        <TextField value={this.state.curUserListName} onChange={(e) => this.setState({ curUserListName: e.target.value })}
                            onBlur={() => this.handleConfirmEditUserList()} inputProps={{ autoFocus: true }}/>
                         : <span>{text}</span>
                )
            },
            { title: 'アクション', dataIndex: 'method',
                render: (text, record, index) => (
                    <div>
                        { this.state.curUserListKey === record.key  && this.state.curUserListEditFlag ?
                            <IconButton style={{ outline: 'none', padding: '0px' }}><DoneIcon/></IconButton>
                            : <IconButton tag='editButton' style={{ outline: 'none', padding: '0px' }} onClick={() => {this.handleEditUserList(record)}}><EditIcon /></IconButton> }
                        <IconButton tag='deleteButton' style={{ outline: 'none', padding: '0px', marginLeft: '20px' }} onClick={() => {this.handleDeleteUserList(record)}}><DeleteIcon /></IconButton>
                    </div>
                ),
            }
        ];

        const columnsUser = [
            { title: '編集フラグ', dataIndex: 'editFlag', width: '50px',
                render: (text, record, index) => (
                    text === 'add' ? <HourglassFullIcon style={{ color: '#1e4620' }}/> : 
                    text === 'delete' ? <HourglassFullIcon style={{ color: '#2f3337'}}/> : <></>
                )
            },
            { title: '氏名', dataIndex: 'name', width: '140px', },
            { title: '組合名', dataIndex: 'companySector', width: '148px', },
            { title: '役職名', dataIndex: 'position', width: '148px', },
            { title: 'アクション', dataIndex: 'method', 
                render: (text, record, index) => (
                    <div>
                        {
                            record.editFlag === 'add' || record.editFlag === 'delete' ? 
                                <IconButton style={{ outline: 'none', padding: '0px' }} onClick={() => {this.handleBackEditedUserInCurUserList(record)}}><ArrowBackIcon /></IconButton>
                                : <IconButton style={{ outline: 'none', padding: '0px' }} onClick={() => {this.handleDeleteUserInCurUserList(record)}}><DeleteIcon /></IconButton>
                        }
                    </div>
                ),
            },
        ];

        const userInfoColumns = [
            { title: '氏名', dataIndex: 'name', width: '140px' },
            { title: '組合名', dataIndex: 'companySector',width: '144px' },
            { title: '役職名', dataIndex: 'position', width: '144px' },
        ];
        const { selectedUserInfoNames } = this.state;
        const rowSelection = {
            selectedUserInfoNames,
            onChange: this.handleUserInfoSelectChange,
        };
        return (
            <Paper elevation={0} className='body-init' style={{width: '1000px' }}>
                <Container style={{ height: '500px' }}className='grid-top'>
                    <h4 style={{ textAlign: 'left' }}>配布リスト管理</h4>
                    <Divider style={{ marginBottom: '20px' }}/>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <h6 style={{ textAlign: 'left' }}>配布リスト</h6>
                        </Grid>
                        <Grid item xs={2}>
                            <h6 style={{ textAlign: 'left' }}>ユーザーリスト</h6>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Button variant='outlined' size='small' onClick={() => this.handleOutputAllUserListsWithUsers()} 
                                style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold', marginLeft: '10px' }}>全ての配布リストを出力</Button>
                            <Button variant='outlined' size='small' onClick = {() => {this.props.history.push('/calendarSchedule')}}
                                style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold', marginLeft: '10px' }}>戻る</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item  xs={4}>
                            <Button variant='outlined' size='small' onClick={ () => this.handleAddUserList() }
                                style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold'}}>追加</Button>
                        </Grid>
                        <Grid container item xs={8}>
                            <Button variant='outlined' size='small' onClick = { () => { this.handleOpenUserInfoDialog() }}
                                style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold' }}>追加</Button>
                            {
                                this.state.userEditedDatas.length > 0 ?
                                <Button variant='outlined' size='small' onClick={ () => { this.handleConfirmEditedUsersInCurUserList()}}
                                    style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold', marginLeft: '20px' }}>確定</Button>
                                : <></>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop:'10px' }}>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Grid item xs={12} style={{ backgroundColor: '#007BFF' }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                            <label style={{ height: '13.4px', width: '164px' }}>配布リスト名</label>
                                                        </td>
                                                        <td style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                            <label style={{ height: '13.4px' }}>アクション</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={ this.state.userListDatas && this.state.userListDatas.length > 0 ? 'userList': 'userListNodata'}>
                                        <Table showHeader={false} pagination={false} columns={columnsDistribute} size='middle'
                                            rowClassName={(record, index) => record.key === this.state.curUserListKey ? 'table-row-color-selected' : index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                            dataSource={ this.state.userListDatas }
                                            onRow={(record) => {
                                                return {
                                                    onClick: (e) => {
                                                        if(!e.target.closest('button')) {
                                                            if(this.state.curUserListKey !== record.key) {
                                                                if(this.state.userEditedDatas.length > 0) {
                                                                    this.setState({
                                                                        cancelOpen: true,
                                                                        newUserListRecord: record,
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        curUserListKey: record.key
                                                                    }, () => {
                                                                        this.handleGetUsersInCurUserList(false)
                                                                    })
                                                                }
                                                            }
                                                        }
                                                    },
                                                };
                                            }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Grid item xs={12} style={{ backgroundColor: '#007BFF' }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td key={1} style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                            <label style={{ height: '13.4px',paddingLeft: '50px', width: '170px' }}>氏名</label>
                                                        </td>
                                                        <td key={3} style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                            <label style={{ height: '13.4px', width: '132px' }}>組合名</label>
                                                        </td>
                                                        <td key={4} style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                            <label style={{ height: '13.4px', width: '132px' }}>役職名</label>
                                                        </td>
                                                        <td key={6} style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                            <label style={{ height: '13.4px', width: this.state.width }}>アクション</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={ this.state.userDatas && this.state.userDatas.length > 0 ? 'userList': 'userListNodata'}>
                                        <Table showHeader={false} pagination={false} columns={columnsUser} size='middle'
                                            rowClassName={(record, index) => record.editFlag === 'add' ? 'table-row-color-added' : record.editFlag === 'delete' ? 
                                                'table-row-color-deleted' : index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                            dataSource={this.state.userDatas}
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => { return record },
                                                };
                                            }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Dialog open={this.state.userInfoDilaogOpen} onClose={() => { this.setState({ userInfoDilaogOpen: false, selectedUserInfoNames: [], })}}>
                            <DialogContent>
                                <Grid container className='grid-top'>
                                    <Grid item xs={8}>
                                        <input type='text' value={this.state.userInfoSearchKey} onChange={(e) => { this.setState({ userInfoSearchKey:e.target.value })}}/>
                                        <Button variant='outlined' size='small' onClick={ () => this.handleGetUserInfo() }
                                            style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold', marginLeft: '20px' }}>検索</Button>
                                    </Grid>
                                </Grid>
                                <Grid container className='grid-top'>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 15 }}>
                                            <span>
                                                { this.state.selectedUserInfoNames.length > 0 ? `ユーザー :  ${this.state.selectedUserInfoNames}` : ''}
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Paper>
                                                <Grid item xs={12} style={{ backgroundColor: '#007BFF' }}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td key={1} style={{ color: 'white', fontWeight: '900', padding: '12px 8px ', textAlign: 'left', }}>
                                                                    <label style={{ height: '13.4px', paddingLeft: '60px', width: '190px'}}>氏名</label>
                                                                </td>
                                                                <td key={3} style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                                    <label style={{ height: '13.4px', width: '148px' }}>組合名</label>
                                                                </td>
                                                                <td key={2} style={{ color: 'white', fontWeight: '900', padding: '12px 8px', textAlign: 'left', }}>
                                                                    <label style={{ height: '13.4px', width: '148px' }}>役職名</label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div onScrollCapture={() => this.handleUserInfoDialogScroll()} style={{ height: '190px', overflowY: 'scroll' }} 
                                                        ref={c => {
                                                            this.scrollRef = c;
                                                        }}>
                                                        <Table showHeader={false} rowSelection={rowSelection} pagination={false} columns={userInfoColumns} size='middle'
                                                            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                                            dataSource={this.state.userInfoDatas} />
                                                    </div>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                <Button color='primary' onClick={() => { this.handleUserInfoDialogAdd() }} style={{ outline: 'none' }} size='small'>追加</Button>
                                <Button color='primary' onClick={() => { this.setState({ userInfoDilaogOpen: false, selectedUserInfoNames: [], }) }} style={{ outline: 'none' }} size='small'>戻る</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Grid>
                        <Dialog open={this.state.deleteOpen} onClose={this.handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle className="dialog-title-style"></DialogTitle>
                            <DialogContent><DialogContentText>削除しますか？</DialogContentText></DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                <Button color="secondary" onClick={(ev) => this.handleConfirmDelete()} style={{ outline: "none" }} size="small">削除</Button>
                                <Button onClick={this.handleDeleteClose} color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Grid>
                        <Dialog open={this.state.cancelOpen} onClose={this.handleCancelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle className="dialog-title-style"></DialogTitle>
                            <DialogContent><DialogContentText>破棄しますか？</DialogContentText></DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                <Button color="secondary" onClick={(ev) => this.handleConfirmCancel()} style={{ outline: "none" }} size="small">破棄</Button>
                                <Button onClick={this.handleCancelClose} color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Container>
            </Paper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.userData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (authData, userData) => dispatch(setAuth(authData, userData)),
        setLoading: (loading) => dispatch(setLoading(loading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHaifu)