import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setLoading } from '../../redux'
import moment from "moment";

import { Paper, Container, Grid, IconButton, TableContainer, TableRow, TableCell, TableBody, Table, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Tooltip } from '@material-ui/core';

import '../../style/calendar.css'


const DateCell = withStyles((theme) => ({
  root: {
    border: 0,
    padding: 6,
    backgroundColor: '#007bff',
    color: '#ffffff'
  },
}))(TableCell);

const EventCell = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    padding: 6,
  },
}))(TableCell);

function DayInfoRow(props) {
  const { info, eventClick, eventOutputQRCode } = props;
  const dateCellStyle = info.date === moment(new Date()).format('YYYYMMDD') ? { fontWeight: 'bold' } : {}

  const EventRow = withStyles((theme) => ({
    root: {
      background: info.date === moment(new Date()).format('YYYYMMDD') ? 'rgba(255, 220, 40, 0.15)' : '',
      '&:hover': {
        background: "#cce5ff",
      },
    }
  }))(TableRow);

  return (
    <>
      <TableRow>
        <DateCell colSpan={4} style={dateCellStyle}>{moment(info.date).format('MM-DD (ddd)')}</DateCell>
      </TableRow>
      {(info.events).map((event) => (
        <EventRow key={event.conferenceId} onClick={(e) => eventClick(e, event)}>
          <EventCell style={{ width: 120 }}>{event.starttime}～{event.endtime}</EventCell>
          <EventCell style={{ width: 350 }}>{event.title}</EventCell>
          <EventCell style={{ width: 350 }}>
            <div style={{ float: 'left' }}>
              会議出欠：{event.attendeeInfo.status === "1" ? "A: 出席 " : event.attendeeInfo.status === "2" ? "A: 欠席 " : event.attendeeInfo.status === "3" ? "A: Web " : ""}
              {event.attendeeInfo.status2 === "1" ? "B: 出席 " : event.attendeeInfo.status2 === "2" ? "B: 欠席 " : event.attendeeInfo.status2 === "3" ? "B: Web " : ""}
              {event.attendeeInfo.status3 === "1" ? "C: 出席 " : event.attendeeInfo.status3 === "2" ? "C: 欠席 " : event.attendeeInfo.status3 === "3" ? "C: Web " : ""}
              {event.attendeeInfo.status4 === "1" ? "D: 出席" : event.attendeeInfo.status4 === "2" ? "D: 欠席" : event.attendeeInfo.status4 === "3" ? "D: Web" : ""}
              {["1", "2", "3"].includes(event.attendeeInfo.status) ? "" : ["1", "2", "3"].includes(event.attendeeInfo.status2) ? "" :
                ["1", "2", "3"].includes(event.attendeeInfo.status3) ? "" : ["1", "2", "3"].includes(event.attendeeInfo.status4) ? "" : "未操作"}
            </div>
            <div style={{ textAlign: 'right' }}>
              <Tooltip title="QRコード発行" placement="bottom">
                <IconButton style={{ outline: "none", padding: "0px" }} onClick={() => eventOutputQRCode(event)}><Icon>qr_code_2</Icon></IconButton>
              </Tooltip>
            </div>
          </EventCell>
          <EventCell style={{ width: 120 }}>報告書：{event.filenum > 0 ? '済' : '未'}</EventCell>
        </EventRow>
      ))}
    </>
  )
}

class CalendarList extends Component {

  render() {
    return (
      <Paper elevation={0} className='body-init' style={{ width: '1000px' }}>
        <Container style={{ height: '500px' }} className='grid-top'>
          <Grid container spacing={2} style={{ marginBottom: 12 }}>
            <Grid item xs={6}>
              <h3>{this.props.month}</h3>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <div className='fc'>
                <div className='fc-toolbar-chunk'>
                  {
                    this.props.month === moment(new Date()).format('YYYY年MM月') ?
                      <Button className='fc-today-button fc-button fc-button-primary' disabled>today</Button>
                      : <Button className='fc-today-button fc-button fc-button-primary' aria-label='today'>today</Button>

                  }
                  <div className='fc-button-group' style={{ marginLeft: 10 }}>
                    <IconButton className='fc-button fc-button-primary' aria-label='prev'>
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton className='fc-button fc-button-primary' aria-label='next'>
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {(this.props.dayInfos).length === 0 ?
              <div>会議予定がありません。</div> :
              <TableContainer style={{ maxHeight: 420, }}>
                <Table size="small" style={{ width: '100%', border: '1px solid rgba(224, 224, 224, 1)' }}>
                  <TableBody>
                    {(this.props.dayInfos).map((dayInfo) => (
                      <DayInfoRow key={dayInfo.date} info={dayInfo} eventClick={this.props.eventClick}
                        eventOutputQRCode={this.props.eventOutputQRCode} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </Container>
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.userData,
    month: state.calendarListData.month,
    dayInfos: state.calendarListData.dayInfos
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (loading) => dispatch(setLoading(loading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalendarList))