import { SET_AUTH } from './authType'

const initialState = {
    auth: null,
    user: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                authState: action.payload.auth,
                user: action.payload.user
            }
        default:
            return state
    }
}

export default authReducer