import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { CloudUpload, CloudDownload } from '@material-ui/icons';
import { Storage, API } from 'aws-amplify'
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
    Link,
    Container,
    Grid,
    Paper,
    Divider,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { connect } from 'react-redux'
import { setMaterial, setLoading } from '../../redux'

import '../../style/conference.css'

class Kaigishiryou extends Component {
    constructor(props) {
        super(props)

        this.state = {
            disabled: 'custom:adminFlag' in this.props.auth.user.attributes &&
                (this.props.auth.user.attributes['custom:adminFlag'] === '1' || this.props.auth.user.attributes['custom:adminFlag'] === '2') ? false : true,
            setFileUpload: false,
            conferenceId: this.props.conferenceId,
            adminFlag: 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0',
            deleteOpen: false,
            index: 0,
        }

        this.fileInput = React.createRef();
    }

    handleFileUpload = () => {
        let file = this.fileInput.current.files[0]
        if (file === undefined) {
            return
        } else {
            this.setState({
                setFileUpload: true
            })
        }
        let newFileName = this.fileInput.current.files[0].name
        let folderName = new Date().valueOf()
        let fileData = {}
        let filesData = [...this.props.materials]
        Storage.put('tmp/' + folderName + '/' + newFileName, file, {
            level: 'public',
        }).then(
            result => {
                fileData = {
                    key: result.key,
                    filename: newFileName,
                }
                filesData.push(fileData)
                this.props.setMaterial(filesData)
                this.fileInput.current.value = ''
                this.setState({
                    setFileUpload: false,
                })
            }
        ).catch(
            err => console.log(err)
        )
    }

    handleDownload = (e, key) => {
        e.preventDefault()
        Storage.get(key, { level: 'public', download: true })
            .then(result => {
                let mimeType = result.ContentType
                let fileName = key.substring(key.lastIndexOf('/') + 1)

                try {
                    let blob = new Blob([result.Body], { type: mimeType })

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName)
                    } else {
                        let objectUrl = URL.createObjectURL(blob);
                        let link = document.createElement('a')
                        link.href = objectUrl
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }
                } catch (err) {
                    console.log(err);
                }

            }).catch(err2 => console.log(err2))
    }

    async handleAllDownload() {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'downloadAllFiles',
                'operator': this.props.auth.user.username,
                'materials': this.props.materials,
            },
            response: true,
        }).then((response) => {
            Storage.get(response.data[0].zipUrl, { level: 'public', download: true })
                .then(result => {
                    let mimeType = result.ContentType
                    let fileName = '会議資料.zip'

                    try {
                        let blob = new Blob([result.Body], { type: mimeType })

                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, fileName)
                        } else {
                            let objectUrl = URL.createObjectURL(blob);
                            let link = document.createElement('a')
                            link.href = objectUrl
                            link.setAttribute('download', fileName)
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch(err2 => console.log(err2))
            this.props.setLoading(false)
        }).catch((err3) => {
            console.log(err3)
            this.props.setLoading(false)
        })
    }

    isCreate = () => {
        return this.state.conferenceId === '' || this.props.infoData === null
    }

    handleClickDeleteOpen = (index) => {
        this.setState({
            deleteOpen: true,
            index: index,
        })
    };

    handleDeleteClose = () => {
        this.setState({
            deleteOpen: false,
        })
    };

    deleteTableData = () => {
        let arr = [...this.props.materials]
        arr.splice(this.state.index, 1)
        this.props.setMaterial(arr)
        this.handleDeleteClose()
    }

    render() {
        const columns = [
            {
                title: '資料名',
                dataIndex: 'filename',
                width: '350px',
                render: (text, record) => (
                    <Link href="#" onClick={(event) => { this.handleDownload(event, record.key); }}>{text}</Link >)
            },
            {
                title: 'アクション',
                dataIndex: 'method',
                width: '175px',
                render: (text, record, index) => (
                    <IconButton disabled={this.state.disabled} style={{ outline: "none", padding: "0px" }} onClick={() => { this.handleClickDeleteOpen(index); }}>
                        <DeleteIcon />
                    </IconButton>),
            },
        ];
        return (
            <div>
                <Container style={{ margin: "70px 0px 40px" }}>
                    <h4>会議資料</h4>
                    <Divider />
                    <Grid container className="grid-top">
                        <Grid item lg={6} xs={6}>
                            {
                                this.state.disabled === false ?
                                    <Button startIcon={<CloudUpload />} variant="outlined" component="label" color="primary"
                                        style={{ outline: "none", color: "#1976D2", fontWeight: "bold" }} size="small" >アップロード
                                        <input type="file" onChange={this.handleFileUpload} style={{ display: "none" }} ref={this.fileInput} />
                                    </Button>
                                    : <Button disabled startIcon={<CloudUpload />} variant="outlined" component="label"
                                        style={{ fontWeight: "bold" }} size="small" >アップロード</Button>
                            }
                            <Button startIcon={<CloudDownload />} variant="outlined" component="label" color="primary" size="small"
                                style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: '5px' }} onClick={() => { this.handleAllDownload() }}>
                                一括ダウンロード
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={7} xs={7}>
                            <Paper>
                                <Table pagination={false} columns={columns} size="middle" dataSource={this.props.materials}
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => { return record }, // 点击表头行
                                        };
                                    }} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Dialog open={this.state.deleteOpen} onClose={this.handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle className="dialog-title-style"></DialogTitle>
                            <DialogContent>
                                <DialogContentText>削除しますか？</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                <Button color="secondary" onClick={() => this.deleteTableData()} style={{ outline: "none" }} size="small">削除</Button>
                                <Button onClick={this.handleDeleteClose} color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Container>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.userData,
        materials: state.infoData.materials
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMaterial: (materials) => dispatch(setMaterial(materials)),
        setLoading: (loading) => dispatch(setLoading(loading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Kaigishiryou)
