import { SET_CALENDARLIST } from './canlendarListType'

const initialState = {
    month: '',
    dayInfos: [],
}

const calendarListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CALENDARLIST:
            return {
                month: action.payload.month,
                dayInfos: action.payload.dayInfos
            }
        default:
            return state
    }
}

export default calendarListReducer