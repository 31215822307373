import React from 'react'
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifyForgotPassword } from '@aws-amplify/ui-react';

function CusAmplifyAuthenticator() {
    return (
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
            <AmplifySignUp slot="sign-up"
                usernameAlias="email"
                formFields={[
                    { type: "email" },
                    { type: "password" },
                    { type: "name", label: "フルネーム *", required: true, placeholder: "フルネームを入力", },
                ]} />
            <AmplifyForgotPassword slot="forgot-password"
                usernameAlias="email" />
        </AmplifyAuthenticator>
    )
}

export default CusAmplifyAuthenticator