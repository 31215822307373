import { SET_AUTH } from './authType'

export const setAuth = (authData, userData) => {
    return {
        type: SET_AUTH,
        payload: {
            auth: authData,
            user: userData
        }
    }
}