import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import moment from "moment";
import { API } from 'aws-amplify'

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Grid, Dialog, Button, ButtonGroup } from '@material-ui/core';

import MenuDialog from "./MenuDialog"
import DeleteDialog from "./DeleteDialog"
import CalendarList from "./CalendarList"
import { setLoading, setCalendarList, } from '../../redux'

import '../../style/calendar.css'

let dataArr = []

class CalendarSchedule extends Component {

  // メソッド・状態値初期化
  constructor(props) {
    super(props)
    this.state = {
      // ダイアログの状態
      open: false,
      openSys: false,
      openDelete: false,
      conferenceId: '',
      style: {},
      // イベント情報
      currentEvents: [],
      eventsData: [],
      selectEventData: {},
      currentDate: '',
      adminFlag: 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0'
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
    this.editEvent = this.editEvent.bind(this)
    this.selectEvent = this.selectEvent.bind(this)
    this.getEvents = this.getEvents.bind(this)

    this.fullCalendar = React.createRef();

    this.handleCloseSys = this.handleCloseSys.bind(this)
    this.tableDom = React.createRef();
  }

  // 取得したデータをイベント集合に追加する
  createEventsData(creator, eventId, eventTitle, startTime, endTime, eventDate) {
    // 時間フォマード
    let evDate = moment(eventDate).format('YYYY-MM-DD');
    let sTime = evDate.concat(" ", startTime, ":00",)
    let eTime = evDate.concat(" ", endTime, ":00",)

    let data = {
      creator: creator,
      id: eventId,
      title: eventTitle,
      start: sTime,
      end: eTime,
    }

    dataArr.push(data)
  }

  onDocumentClick = (e) => {
    let className = e.target.className
    let currentElement = document.activeElement
    // prev
    if (currentElement.ariaLabel === "prev") {
      let date = moment(this.state.currentDate).subtract(1, 'months').format('YYYYMMDD')
      date = date.replace(/[/]/g, "");
      this.setState({
        currentDate: date
      }, () => {
        currentElement.blur()
        dataArr = []
        if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
          this.getEvents(dataArr, this.state.currentDate)
        } else {
          this.getEventsforCalendarList(this.state.currentDate)
        }
      })
    }

    // next
    if (currentElement.ariaLabel === "next") {
      let date = this.state.currentDate
      date = moment(date).add(1, 'months').format('YYYYMMDD')
      date = date.replace(/[/]/g, "");
      this.setState({
        currentDate: date
      }, () => {
        currentElement.blur()
        dataArr = []

        if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
          this.getEvents(dataArr, this.state.currentDate)
        } else {
          this.getEventsforCalendarList(this.state.currentDate)
        }
      })
    }

    if (className === "fc-today-button fc-button fc-button-primary" || currentElement.ariaLabel === "today") {
      this.setState({
        currentDate: moment(new Date()).format('YYYYMMDD')
      }, () => {
        dataArr = []
        if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
          this.getEvents(dataArr, this.state.currentDate)
        } else {
          this.getEventsforCalendarList(this.state.currentDate)
        }
      })
    }

  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false)
  }

  // 画面初期化
  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick, false);
    dataArr = []

    let month = sessionStorage.getItem("currentMonth")
    if (month !== undefined && month !== null && month !== 'Invalid date') {
      this.setState({
        currentDate: moment(month).format('YYYYMMDD')
      }, () => {
        if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
          this.getEvents(dataArr, this.state.currentDate)
          let calendarApi = this.fullCalendar.current.getApi()
          calendarApi.gotoDate(month)
        } else {
          this.getEventsforCalendarList(this.state.currentDate)
        }
        sessionStorage.removeItem("currentMonth")
      })
    } else {
      this.setState({
        currentDate: moment(new Date()).format('YYYYMMDD')
      }, () => {
        if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
          this.getEvents(dataArr, this.state.currentDate)
        } else {
          this.getEventsforCalendarList(this.state.currentDate)
        }
        sessionStorage.removeItem("currentMonth")
      })
    }
  }

  async getEvents(dataArr, date) {
    // イベント情報を取得すること
    this.props.setLoading(true)
    await API.post('uamrsapi', '/conference', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        "operation": "showSchedule",
        "date": date,
        "category": "M",
        "userlist": [
          this.props.auth.user.username
        ],
        'level': this.props.auth.user.attributes['custom:adminFlag'],
        'operator': this.props.auth.user.username
      },
      response: true,
    }).then((response) => {
      // 取得したデータを保存する
      this.setState({ eventsData: response.data });

      // 取得したデータをイベント集合に追加する
      for (let i = 0; i < response.data.length; i++) {
        this.createEventsData(
          response.data[i].creator,
          response.data[i].conferenceId,
          response.data[i].title,
          response.data[i].starttime,
          response.data[i].endtime,
          response.data[i].date,
        )
      }
      this.setState({
        currentEvents: dataArr
      })
      this.props.setLoading(false)
    }).catch((error) => {
      console.log(error)
    })
  }

  async getEventsforCalendarList(date) {
    this.props.setLoading(true)
    await API.post('uamrsapi', '/conference', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        "operation": "showCalendarList",
        "date": date,
        'operator': this.props.auth.user.username
      },
      response: true,
    }).then((response) => {
      this.props.setCalendarList({
        month: moment(date).format('YYYY年MM月'),
        dayInfos: response.data,
      })
      this.props.setLoading(false)
    }).catch((error) => {
      console.log(error)
    })
  }

  // ダイアログボックスを開く
  handleClickOpen = () => {
    this.setState({
      open: true
    })
  };

  handleDeleteClose = () => {
    this.setState({ openDelete: false })
  };

  // ダイアログボックスを閉じる
  handleClose = (actionType, conferenceId) => {
    switch (actionType) {
      case 'edit':
        this.editEvent(conferenceId)
        break;
      case 'delete':
        this.setState({
          openDelete: true,
          conferenceId: conferenceId,
        })
        break;
      case 'select':
        this.selectEvent(conferenceId)
        break;
      case 'return':
        break;
      default: break;
    }
    this.setState({
      open: false
    })
  };

  editEvent(conferenceId) {
    this.props.history.push({
      pathname: "/conference?conferenceId=" + conferenceId,
    })
  }

  selectEvent(conferenceId) {
    this.props.history.push({
      pathname: "/conference?conferenceId=" + conferenceId,
    })
  }

  async deleteEvent() {
    this.setState({ openDelete: false })
    this.props.setLoading(true)
    await API.post('uamrsapi', '/conference', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        "operation": "deleteConference",
        "operator": this.props.auth.user.username,
        "conferenceId": this.state.conferenceId
      },
      response: true,
    }).then((response) => {
      dataArr = []
      this.getEvents(dataArr, this.state.currentDate)
    }).catch((error) => {
      console.log(error)
    })
    this.props.setLoading(false)
  }

  // カレンダー範囲選択時
  handleSelect(info) {
    if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
      localStorage.setItem("date", info.startStr);
      this.props.history.push({
        pathname: "/conference",
      })
    }
  }

  // イベントクリック時
  handleClick(e) {

    let dialogDate = moment(e.event.startStr).format('YYYY年MM月DD日 HH:mm') +
      '～' + moment(e.event.endStr).format('HH:mm')

    let creator

    for (let i = 0; i < this.state.currentEvents.length; i++) {
      if (this.state.currentEvents[i].id.toString() === e.event.id.toString()) {
        creator = this.state.currentEvents[i].creator
      }
    }

    this.setState({
      selectEventData: {
        creator: creator,
        eventId: e.event.id,
        eventTitle: e.event.title,
        eventDate: e.event.endStr.substr(0, 10),
        eventStartTime: e.event.startStr.substr(11, 5),
        eventEndTime: e.event.endStr.substr(11, 5),
        dialogDate: dialogDate
      }
    })
    this.handleClickOpen();
  }

  handleClickforCalendarList = (e, event) => {
    let eClassList = e.target.classList
    if(eClassList.contains('material-icons') || eClassList.contains('MuiIconButton-label')
      || eClassList.contains('MuiButtonBase-root')) {
        // Do nothing
      } else {
        let dialogDate = moment(event.date).format('YYYY年MM月DD日 ') + event.starttime
          + '～' + event.endtime
        this.setState({
          selectEventData: {
            creator: event.creator,
            eventId: event.conferenceId,
            eventTitle: event.title,
            eventDate: event.date,
            eventStartTime: event.starttime,
            eventEndTime: event.endtime,
            dialogDate: dialogDate
          }
        })
        this.handleClickOpen();
      }
  }

  handleCalendarListOutputQRCode = (event) => {
    this.handleOutputQRCode(String(event.conferenceId))
  }

  async handleOutputQRCode(conferenceId) {
    this.props.setLoading(true)
    await API.post('uamrsapi', '/conference', {
        headers: {
            'Content-Type': 'application/json'
        },
        body: {
            'operation': 'outputQRCode',
            'operator': this.props.auth.user.username,
            'conferenceId': conferenceId,
            'username': this.props.auth.user.username
          }
        }).then((data) => {
          const byteCharacters = atob(data[0].image)
          const byteNumbers = new Array(byteCharacters.length)
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          const blob = new Blob([byteArray], { type: 'image/png' })
          const filename = 'qrCode.png'
          if (typeof window.navigator.msSaveBlob != 'undefined') {
              window.navigator.msSaveBlob(blob, filename)
          } else {
              let objectUrl = URL.createObjectURL(blob)
              let link = document.createElement('a')
              link.href = objectUrl
              link.setAttribute('download', filename)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
          }
          this.props.setLoading(false)
        }).catch((error) => {
          console.log(error)
        })
  }

  // システム管理ダイアログボックスを閉じる
  handleCloseSys = () => {
    this.setState({
      openSys: false
    })
  };

  // システム管理ダイアログボックスを開く
  handleClickOpenSys = (ev, a) => {
    this.setState({
      openSys: true,
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        opacity: 1,
        position: 'absolute',
        left: a.offsetLeft + 'px',
        top: a.offsetTop + a.clientHeight + 10 + 'px',
        margin: 0,
      }
    })
  };

  render() {
    return (
      <div className="fullcalendar-div-style">
        <Grid>
          <Grid item lg={12}>
            {
              this.state.adminFlag === '1' || this.state.adminFlag === '2' ?
                <div className="mobile-width">
                  <FullCalendar height='auto' themeSystem='standard' locale="ja" dayMaxEvents={4} ref={this.fullCalendar}
                    fixedWeekCount={false} showNonCurrentDates={false} handleWindowResize={true} selectable={true} weekends={true}
                    customButtons={{ myCustomButton: { text: 'システム管理', click: (ev, a) => this.handleClickOpenSys(ev, a), }, }}
                    headerToolbar={{ left: 'title', center: '', right: 'myCustomButton today prev,next' }}
                    titleFormat={{ year: 'numeric', month: 'short' }}
                    eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false }}
                    businessHours={{ daysOfWeek: [1, 2, 3, 4, 5], startTime: "0:00", endTime: "24:00", }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={this.state.currentEvents} // 起動時に登録するイベント
                    select={info => { this.handleSelect(info) }} // カレンダー範囲選択時
                    eventClick={this.handleClick} // イベントクリック時
                  />
                  <Dialog open={this.state.openSys} onClose={this.handleCloseSys} PaperProps={{ style: this.state.style }} BackdropProps={{ style: { opacity: 0 } }}>
                    <ButtonGroup orientation="vertical" size="large" aria-label="vertical outlined primary button group" variant="contained">
                      <Button onClick={() => { window.location.href = '/userMaintenance' }} style={{ backgroundColor: '#8cbbe9' }}>ユーザー管理</Button>
                      <Button onClick={() => { window.location.href = '/userList' }} style={{ backgroundColor: '#8cbbe9' }}>配布リスト管理</Button>
                      <Button onClick={() => { window.location.href = '/conferenceAttendeeMaintenance' }} style={{ backgroundColor: '#8cbbe9' }}>会議出欠実績入力</Button>
                    </ButtonGroup>
                  </Dialog>
                </div> :
                <CalendarList eventClick={this.handleClickforCalendarList} eventOutputQRCode={this.handleCalendarListOutputQRCode} />
            }
          </Grid>
          <MenuDialog open={this.state.open} selectEventData={this.state.selectEventData} handleClose={this.handleClose} />
          <DeleteDialog open={this.state.openDelete} handleClose={this.handleDeleteClose} handleDelete={this.deleteEvent} />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.userData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (loading) => dispatch(setLoading(loading)),
    setCalendarList: (data) => dispatch(setCalendarList(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalendarSchedule))
