import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { API } from 'aws-amplify'
import { Storage } from 'aws-amplify'
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { Link, Container, Grid, Paper, Divider, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux'
import { setUserMaterial } from '../../redux'
import moment from "moment";

import '../../style/conference.css'

class ConferenceUserDocument extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadDisabled: this.props.auth.user.attributes['custom:adminFlag'] === '1' ? true :
                this.props.auth.user.attributes['custom:adminFlag'] === '2' ? true : false,
            setFileUpload: false,
            conferenceId: this.props.conferenceId,
            tempUserMaterials: [],
            key: '',
            deleteOpen: false,
        }

        this.fileInput = React.createRef();
    }

    handleFileUpload = () => {
        let file = this.fileInput.current.files[0]
        if (file === undefined) {
            return
        } else {
            this.setState({
                setFileUpload: true
            })
        }
        let newFileName = this.fileInput.current.files[0].name
        let folderName = new Date().valueOf()
        let fileData = {}
        let filesData = [...this.state.tempUserMaterials]
        Storage.put('tmp/' + folderName + '/' + newFileName, file, {
            level: 'public',
        }).then(
            result => {
                fileData = {
                    key: result.key,
                    filname: newFileName
                }
                filesData.push(fileData)
                this.fileInput.current.value = ''
                this.setState({
                    setFileUpload: false,
                    tempUserMaterials: filesData
                })
            }
        ).catch(
            err => console.log(err)
        )
    }

    async handleConfirmFileUpload() {
        let tempUserMaterials = this.state.tempUserMaterials
        let tempUserMaterialsData = []
        for (let i = 0; i< tempUserMaterials.length; i++) {
            tempUserMaterialsData.push({
                filekey: tempUserMaterials[i].key,
            })
        }
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "confirmFileUpload",
                "operator": this.props.auth.user.username,
                "conferenceId": this.state.conferenceId,
                "materials": tempUserMaterialsData
            },
            response: true,
        }).then((response) => {
            let data = response.data
            if (data.length > 0) {
                let userMaterials = response.data[0].userMaterials
                let userMaterialsData = []
                for (let j = 0; j < userMaterials.length; j++) {
                    userMaterialsData.push({
                        key: userMaterials[j].filekey,
                        updatetime: userMaterials[j].updatetime,
                        updateusername: userMaterials[j].updateusername,
                        updateuser: userMaterials[j].updateuser
                    })
                }
                this.props.setUserMaterial(userMaterialsData)
            }
            this.setState({
                tempUserMaterials: []
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    handleDownload = (e, key) => {
        e.preventDefault()
        Storage.get(key, { level: 'public', download: true })
            .then(result => {
                let mimeType = result.ContentType
                let fileName = key.substring(key.lastIndexOf('/') + 1)

                try {
                    let blob = new Blob([result.Body], { type: mimeType })

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName)
                    } else {
                        let objectUrl = URL.createObjectURL(blob);
                        let link = document.createElement('a')
                        link.href = objectUrl
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }
                } catch (exc) {
                    console.log(exc);
                }

            }).catch(err => console.log(err))
    }

    isCreate = () => {
        return this.state.conferenceId === '' || this.props.infoData === null
    }

    handleClickDeleteOpen = (key) => {
        this.setState({
            deleteOpen: true,
            key: key,
        })
    };

    handleDeleteClose = () => {
        this.setState({
            deleteOpen: false,
        })
    };

    async deleteTableData () {
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "deleteUserMaterial",
                "operator": this.props.auth.user.username,
                "conferenceId": this.state.conferenceId,
                "filekey": this.state.key
            },
            response: true,
        }).then((response) => {
            let data = response.data
            if (data.length > 0) {
                let userMaterials = response.data[0].userMaterials
                let userMaterialsData = []
                for (let j = 0; j < userMaterials.length; j++) {
                    userMaterialsData.push({
                        key: userMaterials[j].filekey,
                        updatetime: userMaterials[j].updatetime,
                        updateusername: userMaterials[j].updateusername,
                        updateuser: userMaterials[j].updateuser
                    })
                }
                this.props.setUserMaterial(userMaterialsData)
            }
        }).catch((error) => {
            console.log(error)
        })
        this.handleDeleteClose()
    }

    deleteTempUserMaterial = (data, index) => {
        Storage.remove(data.key, { level: 'public' })
            .then(result => {
                let arr = [...this.state.tempUserMaterials]
                arr.splice(index, 1)
                this.setState({
                    tempUserMaterials: arr
                })
            }).catch(err => console.log(err))
    }

    render() {
        const columns = [
            {
                title: '資料名',
                dataIndex: 'key',
                width: '350px',
                render: (text) => (
                    <Link href="#" onClick={(event) => { this.handleDownload(event, text); }}>
                        {text.substring(text.indexOf('/') + 1)}
                    </Link >
                )
            },
            {
                title: '更新者',
                dataIndex: 'updateuser',
                width: '180px',
            },
            {
                title: '更新日付',
                dataIndex: 'updatetime',
                width: '195px',
                render: (text) => (
                    <>
                        {moment.utc(text).local().format('YYYY/MM/DD HH:mm')}
                    </>
                )
            },
            {
                title: 'アクション',
                dataIndex: 'method',
                width: "175px",
                render: (text, record, index) => (
                    <IconButton disabled={ this.props.auth.user.username === record.updateusername || this.props.auth.user.attributes['custom:adminFlag'] === '1' ||
                        this.props.auth.user.attributes['custom:adminFlag'] === '2' ? false : true } style={{ outline: "none", padding: "0px" }}
                        onClick={() => { this.handleClickDeleteOpen(record.key) }}>
                        <DeleteIcon />
                    </IconButton>),
            },
        ];
        return (
            <div>
                <Container style={{ margin: "70px 0px 40px" }}>
                    <h4>参加者資料アップロード</h4>
                    <Divider />
                    <Grid container className="grid-top">
                        <Grid item lg={6} xs={6}>
                            {
                                this.state.uploadDisabled === false && this.props.attendeesExists === true?
                                    <Button startIcon={<CloudUploadIcon />} variant="outlined" component="label" color="primary" size="small"
                                        style={{ outline: "none", color: "#1976D2", fontWeight: "bold" }}>アップロード
                                        <input type="file" onChange={this.handleFileUpload} style={{ display: "none" }} ref={this.fileInput} />
                                    </Button>
                                    :
                                    <Button disabled startIcon={<CloudUploadIcon />} variant="outlined" component="label" size="small" 
                                        style={{fontWeight: "bold" }}>アップロード
                                    </Button>
                            }
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            { this.state.tempUserMaterials.length > 0 ?  
                                <div style={{background:"#cce5ff", textAlign: 'left', marginTop: 10, padding: 5}}>
                                    { 
                                        this.state.tempUserMaterials.map((data, index) =>{
                                            let filename = data.filname;
                                            return (
                                                <li style={{ listStyleType: "none" }} key={data.key}>
                                                    <Chip label={filename} onClick={(e) => this.handleDownload(e, data.key)} variant='outlined'
                                                        onDelete={()=>this.deleteTempUserMaterial(data, index)} style={{ color: '#1976D2', background:'#ffffff' }}/>
                                                </li>
                                            )
                                        })
                                    }
                                    <div style={{background:"#cce5ff", textAlign: 'right', paddingBottom: 5}}>
                                        <Button variant="outlined" size="small" onClick={()=>this.handleConfirmFileUpload()}
                                            style={{ outline: 'none', color: '#1976D2', background:'#ffffff', fontWeight: 'bold', marginRight: '10px' }}>確定</Button>
                                    </div>
                                </div> 
                                : <></>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={12} xs={12}>
                            <Paper>
                                <Table pagination={false} size="middle" columns={columns} dataSource={this.props.userMaterials}
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => { return record }, // 点击表头行
                                        };
                                    }} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Dialog open={this.state.deleteOpen} onClose={this.handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle className="dialog-title-style"></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.isCreate() ? '破棄しますか？' : '削除しますか？'}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                {this.isCreate() ?
                                <Button color="secondary" style={{ outline: "none" }} size="small" onClick={() => this.deleteTableData()}>破棄</Button>
                                    :<Button color="secondary" style={{ outline: "none" }} size="small" onClick={() => this.deleteTableData()}>削除</Button>}
                                <Button color="primary" style={{ outline: "none" }} size="small" onClick={this.handleDeleteClose}>キャンセル</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Container>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    let attendees = state.infoData.info.attendees
    let attendeesExists = true
    if(attendees) {
        attendeesExists = attendees.map(obj => obj.username).includes(state.auth.user.username)
    }
    return {
        auth: state.auth,
        userData: state.userData,
        userMaterials: state.infoData.userMaterials,
        attendeesExists: attendeesExists
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserMaterial: (materials) => dispatch(setUserMaterial(materials)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceUserDocument)
