import React, { Component } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import 'moment/locale/ja'
import localeJa from 'antd/lib/date-picker/locale/ja_JP';
import { Checkbox, DatePicker, Radio, Table, TimePicker } from 'antd';
import { API } from 'aws-amplify'
import { setMeeting, setMaterial, setUserMaterial, setLoading } from '../../redux'
import { connect } from 'react-redux'
import { Container, Grid, Paper, Divider, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import 'antd/dist/antd.css';
import '../../style/conference.css';

moment.locale('ja');
const dateFormat = 'YYYY年MM月DD日';
const clearStatus = { status: '', status2: '', status3: '', status4: '', status5: '', status6: '', status7: '', status8: '', status9: '', status10: ''};
const clearResultStatus = { result_status: '', result_status2: '', result_status3: '', result_status4: '', result_status5: '', result_status6: '', result_state7: '',
                            result_state8: '', result_state9: '', result_state10: ''};
const clearExternalState = { username: '', name: '', nameNullCheck: true, email: '', emailNullCheck: true, mailCheck: true, department: '', position: '', tablet: '' };
const clearMessageDialogState = { messageDialogOpen: false, message: ''}
const clearSections = { section_enable: '0', section: '', section2_enable: '0', section2: '', section3_enable: '0', section3: '',
                        section4_enable: '0', section4: '', section5_enable: '0', section5: '', section6_enable: '0', section6: '',
                        section7_enable: '0', section7: '', section8_enable: '0', section8: '', section9_enable: '0', section9: '',
                        section10_enable: '0', section10: '', }

const clearSections_no = { section_no: 'A', section2_no: 'A', section3_no: 'A', section4_no: 'A', section5_no: 'A', section6_no: 'A',
                           section7_no: 'A', section8_no: 'A', section9_no: 'A', section10_no: 'A', }
const clearStatusRadioGroups_err = { status_err: false, status2_err: false, status3_err: false, status4_err: false, status5_err: false,
                                          status6_err: false, ststus7_err: false, status8_err: false, status9_err: false, status10_err: false, }

class Kaiginaiyou extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // ダイアログの状態
            open: false,
            disabled: false,
            conferenceId: this.props.conferenceId,
            rowsData: [],
            userInfoKeys: [],
            userInfoSearchKey: '',
            selectedUserInfoNames: [],
            addTableRows: [],
            ...clearStatus,
            ...clearStatusRadioGroups_err,
            title: '',
            date: localStorage.getItem("date") ? moment(localStorage.getItem("date")).format("YYYYMMDD") : moment(new Date()).format('YYYYMMDD'),
            startTime: undefined,
            endTime: undefined,
            room: "",
            webconferenceInfo: "",
            webconferenceRemark: "",
            ...clearSections,
            ...clearSections_no,

            timeCheck: true,
            roomNullCheck: true,
            titleNullCheck: true,
            sTimeCheck: true,
            eTimeCheck: true,

            paginationToken: '',
            isBottom: false,
            width: '',
            adminFlag: 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0',

            psOpen: false,
            psUsername: '',
            psResultFlg: false,

            deleteOpen: false,
            record: [],

            externalOpen: false,
            ...clearExternalState,

            ...clearMessageDialogState,
        }
        this.getPageInfo = this.getPageInfo.bind(this)
        this.getUserInfo = this.getUserInfo.bind(this)
        this.handlePsDialogConfirm = this.handlePsDialogConfirm.bind(this)
        this.handleAfterPsDialogConfirm = this.handleAfterPsDialogConfirm.bind(this)
        this.getNextUserInfo = this.getNextUserInfo.bind(this)
        this.handleTabetRental = this.handleTabetRental.bind(this)
        this.handleAfterTabetRental = this.handleAfterTabetRental.bind(this)
        this.handleOutputAttendee = this.handleOutputAttendee.bind(this)
        this.handleAdd = this.handleAdd.bind(this)

        this.tableDom = React.createRef();
    }

    componentDidMount() {
        // 画面情報を取得
        if (this.state.conferenceId !== undefined && this.state.conferenceId !== null && this.state.conferenceId !== '') {
            this.getPageInfo(this.state.conferenceId);
        } else {
            this.props.setMaterial([])
            this.props.setUserMaterial([])
            this.setState({
                rowsData: [
                    {
                        key: this.props.auth.user.username,
                        level: this.props.auth.user.attributes['custom:adminFlag'],
                        name: this.props.auth.user.attributes.name,
                        companySector: this.props.auth.user.attributes['custom:department'],
                        mail: this.props.auth.user.attributes.email,
                        position: this.props.auth.user.attributes['custom:position'],
                        ...clearStatus,
                        tablet: '',
                        ...clearResultStatus,
                    }
                ]
            })
        }

        // 出席者情報を取得
        this.props.onNaiyouRef(this);
    }

    // チェック
    validateFalse = (type) => {
        switch (type) {
            case 'title':
                this.setState({ titleNullCheck: false })
                break;
            case 'sTime':
                this.setState({ sTimeCheck: false });
                break;
            case 'eTime':
                this.setState({ eTimeCheck: false });
                break;
            case 'room':
                this.setState({ roomNullCheck: false });
                break;
            case 'name':
                this.setState({ nameNullCheck: false });
                break;
            case 'email':
                this.setState({ emailNullCheck: false });
                break;
            default: break;
        }
    }

    // 検索ボタンで出席者情報を取得
    searchUser() {
        this.handleGetAddAttendeeInfo()
    }

    // 画面情報を取得
    async getPageInfo(conferenceId) {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "showConference",
                "conferenceId": conferenceId
            },
            response: true,
        }).then((response) => {
            if (response.data.length > 0) {
                this.props.setMeeting(response.data[0])
                let attendees = response.data[0].attendees
                let attendeesData = []
                for (let i = 0; i < attendees.length; i++) {
                    attendeesData.push(
                        {
                            key: attendees[i].username,
                            companySector: attendees[i].department,
                            ...attendees[i]
                        }
                    )
                }
                let materials = response.data[0].materials
                let materialsData = []
                for (let i = 0; i < materials.length; i++) {
                    materialsData.push(
                        {
                            key: materials[i].filekey,
                            filename: materials[i].filekey.substring(materials[i].filekey.indexOf('/') + 1),
                        }
                    )
                }
                this.props.setMaterial(materialsData)
                let userMaterials = response.data[0].userMaterials
                let userMaterialsData = []
                for (let j = 0; j < userMaterials.length; j++) {
                    userMaterialsData.push({
                        key: userMaterials[j].filekey,
                        updatetime: userMaterials[j].updatetime,
                        updateusername: userMaterials[j].updateusername,
                        updateuser: userMaterials[j].updateuser
                    })
                }
                this.props.setUserMaterial(userMaterialsData)
                let attendeeDataUsernameList = attendeesData.map(obj => obj.key)
                let newSessions = {}
                let newSessions_no = {}
                let section_counter = 0
                for (let i = 1; i <= 10; i++) {
                    let tmp_section_enable_key = 'section_enable'
                    let tmp_section_key = 'section'
                    let tmp_section_no_key = 'section_no'
                    if (i > 1) {
                        tmp_section_enable_key = 'section' + i + '_enable'
                        tmp_section_key = 'section' + i
                        tmp_section_no_key = 'section' + i + '_no'
                    }
                    if(response.data[0].hasOwnProperty(tmp_section_enable_key) && response.data[0][tmp_section_enable_key] === '1') {
                        newSessions[tmp_section_enable_key] = '1'
                        newSessions[tmp_section_key] = response.data[0][tmp_section_key]
                        newSessions_no[tmp_section_no_key] = String.fromCharCode('A'.charCodeAt(0) + section_counter);
                        section_counter = section_counter + 1
                    }
                }
                this.setState({
                    title: response.data[0].title,
                    date: response.data[0].date,
                    startTime: response.data[0].starttime,
                    endTime: response.data[0].endtime,
                    room: response.data[0].room,
                    webconferenceInfo: response.data[0].webconference_info,
                    webconferenceRemark: response.data[0].webconference_remark,
                    ...newSessions,
                    ...newSessions_no,
                    rowsData: attendeesData,
                    disabled: this.state.adminFlag === '1' ? false :
                        (this.state.adminFlag === '2' && attendeeDataUsernameList.includes(this.props.auth.user.username)) ? false : true,
                }, () => {
                    sessionStorage.setItem("currentMonth", this.state.date)
                })
            }
            this.props.setLoading(false)
        }).catch((error) => {
            console.log(error)
            this.props.setLoading(false)
        })
    }

    // 出席者情報を取得
    async getUserInfo() {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "getUsers",
                "filter": {
                    "name": "name",
                    "value": this.state.userInfoSearchKey
                }
            },
            response: true,
        }).then((response) => {
            let data = response.data[0].users
            let datas = [...this.state.addTableRows]
            for (let i = 0; i < data.length; i++) {
                datas.push(
                    {
                        key: 'user_' + data[i].username,
                        name: data[i].name,
                        companySector: data[i].department,
                        position: data[i].position,
                    }
                )
            }
            this.setState({
                addTableRows: datas,
                paginationToken: response.data[0].paginationToken
            }, () => {
                if (this.state.paginationToken === undefined) {
                    this.setState({
                        isBottom: true
                    })
                }
            });
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    onSelectChange = userInfoKeys => {
        let newSelectedUserInfoNames = []
        let addTableRows = this.state.addTableRows
        for (let i = 0; i < addTableRows.length; i++) {
            if (userInfoKeys.includes(addTableRows[i].key)) {
                newSelectedUserInfoNames.push(addTableRows[i].name)
            }
        }
        this.setState({
            userInfoKeys,
            selectedUserInfoNames: newSelectedUserInfoNames
        })
    };

    preventDefault = (event) => event.preventDefault();

    handleClickOpen = () => {
        this.setState({
            open: true
        })
        this.handleGetAddAttendeeInfo()
    };

    handleClose = () => {
        this.setState({
            open: false,
            selectedUserInfoNames: [],
        })
    };

    async handleAdd() {
        this.props.setLoading(true)
        let addDatas = []
        for (let i = 0; i < this.state.addTableRows.length; i++) {
            if (this.state.userInfoKeys.includes(this.state.addTableRows[i].key)) {
                let key = this.state.addTableRows[i].key
                if (key.startsWith('user_')) {
                    addDatas.push({
                        key: this.state.addTableRows[i].key.substring('user_'.length),
                        name: this.state.addTableRows[i].name,
                        companySector: this.state.addTableRows[i].companySector,
                        position: this.state.addTableRows[i].position,
                        ...clearStatus,
                        tablet: '',
                        ...clearResultStatus,
                    })
                } else if (key.startsWith('userList_')) {
                    await API.post('uamrsapi', '/conference', {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: {
                            'operation': 'getUsersByUserListid',
                            'listid': this.state.addTableRows[i].key.substring('userList_'.length),
                        }
                    }).then((response) => {
                        if (response && response.length > 0) {
                            let data = response[0].users
                            for (let index in data) {
                                addDatas.push({
                                    key: data[index].username,
                                    name: data[index].name,
                                    companySector: data[index].department,
                                    position: data[index].position,
                                    ...clearStatus,
                                    tablet: '',
                                    ...clearResultStatus,
                                })
                            }
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        }

        let datas = this.state.rowsData
        for (let i = 0; i < this.state.rowsData.length; i++) {
            for (let x = 0; x < addDatas.length; x++) {
                if (addDatas[x].key === this.state.rowsData[i].key) {
                    addDatas.splice(x, 1)
                }
            }
        }

        datas = datas.concat(addDatas)

        this.setState({
            open: false,
            rowsData: datas,
            selectedUserInfoNames: [],
        })
        this.props.setLoading(false)
    };

    isCreate = () => {
        return this.state.conferenceId === '' || this.props.infoData === null
    }

    handleClickDeleteOpen = (record) => {
        this.setState({
            deleteOpen: true,
            record: record,
        })
    };

    handleDeleteClose = () => {
        this.setState({
            deleteOpen: false,
        })
    };

    deleteTableData = () => {
        let arr = [...this.state.rowsData]
        let index = 0
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].key === this.state.record.key) {
                index = i
            }
        }
        arr.splice(index, 1)
        this.setState({
            rowsData: arr
        })
        this.handleDeleteClose()
    }

    handleChange = (name, e) => {
        if (name.startsWith('section') && name.indexOf('_enable') > 0) {
            let nameMatch = name.match(/section(.*?)_/)
            let section_no = nameMatch ? nameMatch[1] : ''
            let section_name = 'section' + section_no
            let status_name = 'status' + section_no
            let result_status_name = 'result_status' + section_no
            let new_section_value = ''
            let new_rowsData = this.state.rowsData
            new_rowsData.forEach(rowData => {
                rowData[status_name] = ''
                rowData[result_status_name] = ''
            })
            let newSessions_no = {}
            let section_no_num = (section_no === '' ? 1 : parseInt(section_no, 10))
            if (this.state[name] === '1') {
                for (let i = 1; i<= 10; i++) {
                    let tmp_section_no_key = (i === 1 ? 'section_no' : 'section' + i + '_no')
                    let tmp_section_enable_key = (i === 1 ? 'section_enable' : 'section' + i + '_enable')
                    if (i === section_no_num) {
                        newSessions_no[tmp_section_no_key] = 'A'
                    } else if ( i > section_no_num) {
                        if (this.state[tmp_section_enable_key] === '1') {
                            newSessions_no[tmp_section_no_key] = String.fromCharCode(this.state[tmp_section_no_key].charCodeAt(0) - 1)
                        }
                    }
                }
                this.setState({
                    [name]: '0',
                    [section_name]: new_section_value,
                    [status_name]: '',
                    [result_status_name]: '',
                    rowsData: new_rowsData,
                    ...newSessions_no,
                })
            } else {
                new_section_value = name.startsWith('section8') ? '食事会①' : name.startsWith('section9') ? '食事会②' : name.startsWith('section10') ? '宿泊' : ''
                let tmp_section_counter = 0
                for (let i = 1; i<= 10; i++) {
                    let tmp_section_no_key = (i === 1 ? 'section_no' : 'section' + i + '_no')
                    let tmp_section_enable_key = (i === 1 ? 'section_enable' : 'section' + i + '_enable')
                    if (i < section_no_num) {
                        if (this.state[tmp_section_enable_key] === '1') {
                            tmp_section_counter++
                        }
                    } else if (i === section_no_num) {
                        newSessions_no[tmp_section_no_key] = String.fromCharCode('A'.charCodeAt(0) + tmp_section_counter)
                    } else if ( i > section_no_num) {
                        if (this.state[tmp_section_enable_key] === '1') {
                            newSessions_no[tmp_section_no_key] = String.fromCharCode(this.state[tmp_section_no_key].charCodeAt(0) + 1)
                        }
                    }
                }
                this.setState({
                    [name]: '1',
                    [section_name]: new_section_value,
                    ...newSessions_no,
                })
            }
        } else {
            const { value } = e.target;
            this.setState({
                [name]: value
            }, () => {
                if (name === 'title' && this.state.title !== '') {
                    this.setState({ titleNullCheck: true })
                } else if (name === 'room' && this.state.room !== '') {
                    this.setState({ roomNullCheck: true })
                } else if (name === 'name' && this.state.name !== '') {
                    this.setState({ nameNullCheck: true })
                } else if (name === 'email' && this.state.email !== '') {
                    this.setState({ emailNullCheck: true, mailCheck: true })
                }
            });
        }
    }

    handeleGetInfoNaiyou() {
        return this.state
    }

    ondateChange = (date) => {
        this.setState({
            date: moment(date).format("YYYYMMDD")
        }, () => {
            sessionStorage.setItem("currentMonth", this.state.date)
        });
    }

    onStartTimeChange = (time) => {
        this.setState({
            startTime: moment(time).format("HH:mm")
        }, () => {
            if (this.state.startTime !== undefined && this.state.startTime !== "") {
                this.setState({
                    sTimeCheck: true
                })
            }
            this.timeCheck(this.state.startTime, this.state.endTime)
        });
    }

    onEndTimeChange = (time) => {
        this.setState({
            endTime: moment(time).format("HH:mm")
        }, () => {
            if (this.state.endTime !== undefined && this.state.endTime !== "") {
                this.setState({
                    eTimeCheck: true
                })
            }
            this.timeCheck(this.state.startTime, this.state.endTime)
        });
    }

    timeCheck = (startTime, endTime) => {
        if (startTime === undefined || endTime === undefined) {
            return
        }

        if (startTime >= endTime) {
            this.setState({
                timeCheck: false
            });
        } else {
            this.setState({
                timeCheck: true
            });
        }
    }

    onScrollHandle = () => {
        // eslint-disable-next-line
        const scrollTop = this.scrollRef.scrollTop;
        // eslint-disable-next-line
        const clientHeight = this.scrollRef.clientHeight;
        // eslint-disable-next-line
        const scrollHeight = this.scrollRef.scrollHeight;
        const isBottom = Math.ceil(scrollTop) + clientHeight === scrollHeight;
        if (isBottom) {
            this.getNextUserInfo(this.state.paginationToken)
        }
    }

    async getNextUserInfo(paginationToken) {
        this.props.setLoading(true)
        if (this.state.isBottom) {
            this.props.setLoading(false)
            return
        }
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "getUsers",
                "paginationToken": paginationToken
            },
            response: true,
        }).then((response) => {
            let data = response.data[0].users
            let datas = []
            for (let i = 0; i < data.length; i++) {
                datas.push(
                    {
                        key: 'user_' + data[i].username,
                        name: data[i].name,
                        companySector: data[i].department,
                        position: data[i].position,
                    }
                )
            }
            datas = this.state.addTableRows.concat(datas)
            this.setState({
                addTableRows: datas,
                paginationToken: response.data[0].paginationToken
            }, () => {
                if (this.state.paginationToken === undefined) {
                    this.setState({
                        isBottom: true
                    })
                }
            });
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    onEntered = () => {
        this.setState({
            width: this.tableDom.current.clientWidth - 296 - 26
        }, () => { })
    }

    handlePsDialogOpen = (record, resultFlg) => {
        this.setState({
            status: resultFlg ? record.result_status : record.status,
            status2: resultFlg ? record.result_status2 : record.status2,
            status3: resultFlg ? record.result_status3 : record.status3,
            status4: resultFlg ? record.result_status4 : record.status4,
            status5: resultFlg ? record.result_status5 : record.status5,
            status6: resultFlg ? record.result_status6 : record.status6,
            status7: resultFlg ? record.result_status7 : record.status7,
            status8: resultFlg ? record.result_status8 : record.status8,
            status9: resultFlg ? record.result_status9 : record.status9,
            status10: resultFlg ? record.result_status10 : record.status10,
            psOpen: true,
            psUsername: record.key,
            psResultFlg: resultFlg
        })

    }

    handlePsDialogClose = () => {
        this.setState({
            ...clearStatus,
            ...clearStatusRadioGroups_err,
            psOpen: false,
            psUsername: ''
        })
    }

    async handlePsDialogConfirm() {
        if (this.state.adminFlag === '0') {
            let newStatusRadioGroups_err = {}
            for (let i = 1; i<= 10; i++) {
                let tmp_section_enable_key = (i === 1 ? 'section_enable' : 'section' + i + '_enable')
                let tmp_status_key = (i === 1 ? 'status' : 'status' + i)
                let tmp_statusRadioGroups_err_key = (i === 1 ? 'status_err' : 'status' + i + '_err')
                if (this.state[tmp_section_enable_key] === '1' && this.state[tmp_status_key] ===　'') {
                    newStatusRadioGroups_err[tmp_statusRadioGroups_err_key] = true
                }
            }
            if(Object.keys(newStatusRadioGroups_err).length > 0) {
                alert('すべてセッションの予定を入力してください。')
                this.setState({ ...newStatusRadioGroups_err })
                return
            }
            this.props.setLoading(true)
            await API.post('uamrsapi', '/conference', {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    "operation": "updatePresenceStatus",
                    "operator": this.props.auth.user.username,
                    'username': this.state.psUsername,
                    "conferenceId": this.state.conferenceId,
                    "status": this.state.status,
                    "status2": this.state.status2,
                    "status3": this.state.status3,
                    "status4": this.state.status4,
                    "status5": this.state.status5,
                    "status6": this.state.status6,
                    "status7": this.state.status7,
                    "status8": this.state.status8,
                    "status9": this.state.status9,
                    "status10": this.state.status10
                },
                response: true,
            }).then((response) => {
                this.handleAfterPsDialogConfirm()
            }).catch((error) => {
                console.log(error)
            })
            this.props.setLoading(false)
        } else {
            this.handleAfterPsDialogConfirm()
        }
    }

    handleAfterPsDialogConfirm = () => {
        let datas = this.state.rowsData
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].key === this.state.psUsername) {
                if (this.state.psResultFlg) {
                    datas[i].result_status = this.state.status
                    datas[i].result_status2 = this.state.status2
                    datas[i].result_status3 = this.state.status3
                    datas[i].result_status4 = this.state.status4
                    datas[i].result_status5 = this.state.status5
                    datas[i].result_status6 = this.state.status6
                    datas[i].result_status7 = this.state.status7
                    datas[i].result_status8 = this.state.status8
                    datas[i].result_status9 = this.state.status9
                    datas[i].result_status10 = this.state.status10
                } else {
                    datas[i].status = this.state.status
                    datas[i].status2 = this.state.status2
                    datas[i].status3 = this.state.status3
                    datas[i].status4 = this.state.status4
                    datas[i].status5 = this.state.status5
                    datas[i].status6 = this.state.status6
                    datas[i].status7 = this.state.status7
                    datas[i].status8 = this.state.status8
                    datas[i].status9 = this.state.status9
                    datas[i].status10 = this.state.status10
                }
            }
        }
        this.setState({
            rowsData: datas,
            psOpen: false,
            psUsername: '',
            ...clearStatus,
            ...clearStatusRadioGroups_err,
        })
    }

    async handleTabetRental(record, newTablet) {
        if (this.state.adminFlag === '0') {
            this.props.setLoading(true)
            await API.post('uamrsapi', '/conference', {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    "operation": "updateTabletRental",
                    "operator": this.props.auth.user.username,
                    "username": record.key,
                    "conferenceId": this.state.conferenceId,
                    "tablet": newTablet,
                },
                response: true,
            }).then((response) => {
                this.handleAfterTabetRental(record, newTablet)
            }).catch((error) => {
                console.log(error)
            })
            this.props.setLoading(false)
        } else {
            this.handleAfterTabetRental(record, newTablet)
        }
    }

    handleAfterTabetRental = (record, newTablet) => {
        let datas = this.state.rowsData
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].key === record.key) {
                datas[i].tablet = newTablet
            }
        }
        this.setState({
            rowsData: datas,
        })
    }

    async handleOutputAttendee() {
        this.props.setLoading(true)
        let same_keys = ['name', 'position', 'status', 'status2', 'status3', 'status4', 'status5', 'status6', 'status7', 'status8',
            'status9', 'status10', 'tablet', 'result_status', 'result_status2', 'result_status3', 'result_status4', 'result_status5',
            'result_status6', 'result_status7', 'result_status8', 'result_status9', 'result_status10']
        const attendees = this.state.rowsData.map(obj => {
            return Object.assign({}, { 'username': obj.key, 'department': obj.companySector, },
                Object.fromEntries(Object.entries(obj).filter(([key, value]) => same_keys.includes(key))))
        })
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "outputAttendee",
                "operator": this.props.auth.user.username,
                "title": this.state.title,
                "date": this.state.date,
                "starttime": this.state.startTime,
                "endtime": this.state.endTime,
                "attendees": attendees,
                "room": this.state.room,
                "section_enable": this.state.section_enable,
                "section": this.state.section,
                "section2_enable": this.state.section2_enable,
                "section2": this.state.section2,
                "section3_enable": this.state.section3_enable,
                "section3": this.state.section3,
                "section4_enable": this.state.section4_enable,
                "section4": this.state.section4,
                "section5_enable": this.state.section5_enable,
                "section5": this.state.section5,
                "section6_enable": this.state.section6_enable,
                "section6": this.state.section6,
                "section7_enable": this.state.section7_enable,
                "section7": this.state.section7,
                "section8_enable": this.state.section8_enable,
                "section8": this.state.section8,
                "section9_enable": this.state.section9_enable,
                "section9": this.state.section9,
                "section10_enable": this.state.section10_enable,
                "section10": this.state.section10,
            },
            response: true,
        }).then((response) => {
            const byteCharacters = atob(response.data[0].excel);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const filename = "出席者リスト.xlsx"
            if (typeof window.navigator.msSaveBlob != 'undefined') {
                window.navigator.msSaveBlob(blob, filename)
            } else {
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a')
                link.href = objectUrl
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    async handleGetAddAttendeeInfo() {
        this.props.setLoading(true)
        let newUserListDatas = []
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'getUserList',
            }
        }).then((response) => {
            let data = response[0]
            if (data.userList && data.userList.length > 0) {
                for (let i = 0; i < data.userList.length; i++) {
                    if (!this.state.userInfoSearchKey || data.userList[i].listname.includes(this.state.userInfoSearchKey)) {
                        newUserListDatas.push({
                            key: 'userList_' + data.userList[i].listid,
                            name: data.userList[i].listname + ' (リスト)'
                        })
                    }
                }
            }
        }).catch((error) => {
            console.log(error)
        })

        this.setState({
            addTableRows: newUserListDatas
        }, () => { this.getUserInfo() })
    }

    handleAddExternalUser = () => {
        // validate
        let errorMsg = ''
        if (this.state.name === '' || this.state.email === '') {
            errorMsg += '必須項目が記入されていない'
            if (this.state.name === '')
                this.validateFalse('name')
            if (this.state.email === '')
                this.validateFalse('email')
        }
        let mail_regexp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        if (this.state.email.match(mail_regexp) == null) {
            this.validateFalse('email')
            errorMsg += ((errorMsg.length > 0 ? '\n': '') + '正しいメールアドレスを入力してください')
        }
        if(errorMsg.length > 0) {
            alert(errorMsg)
            return
        }
        let datas = this.state.rowsData
        if(this.state.username === '') {
            datas = datas.concat({
                key: 'ex_' + this.props.auth.user.username + '_' + Math.round(new Date().getTime() / 1000),
                name: this.state.name, email: this.state.email, companySector: this.state.department,
                position: this.state.position, status: this.state.status, status2: this.state.status2,
                status3: this.state.status3, status4: this.state.status4, status5: this.state.status5,
                status6: this.state.status6, status7: this.state.status7, status8: this.state.status8,
                status9: this.state.status9, status10: this.state.status10, tablet: this.state.tablet,
                ...clearResultStatus
            })
        } else {
            for (let i = 0; i < datas.length; i++) {
                if(this.state.username === datas[i]['key']) {
                    datas[i].name = this.state.name
                    datas[i].email = this.state.email
                    datas[i].companySector = this.state.department
                    datas[i].position = this.state.position
                    datas[i].status = this.state.status
                    datas[i].status2 = this.state.status2
                    datas[i].status3 = this.state.status3
                    datas[i].status4 = this.state.status4
                    datas[i].status5 = this.state.status5
                    datas[i].status6 = this.state.status6
                    datas[i].status7 = this.state.status7
                    datas[i].status8 = this.state.status8
                    datas[i].status9 = this.state.status9
                    datas[i].status10 = this.state.status10
                    datas[i].tablet = this.state.tablet
                }
            }
        }
        this.setState({ rowsData: datas, externalOpen: false, ...clearExternalState, ...clearStatus, })
    }

    async handleSendQRCode(record) {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'sendQRCode',
                'operator': this.props.auth.user.username,
                'conferenceId': this.state.conferenceId,
                'username': record.key
            }
        }).then((response) => {
            this.setState({
                messageDialogOpen: true,
                message: 'QRコードを送信しました。'
            })
            this.props.setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    render() {
        const columns = [
            {
                title: '氏名',
                width: '108px',
                render: (record) => ((record.key.startsWith('ex_') ? '(外)': '') + record.name),
            },
            {
                title: '組合名',
                dataIndex: 'companySector',
                width: "148px",
            },
            {
                title: '役職名',
                dataIndex: 'position',
                width: "128px",
            },
            {
                title: '出欠',
                colSpan: 2,
                align: 'left',
                ellipsis: true,
                render: (record) => (
                    <>
                        <span style={{whiteSpace: 'nowrap'}}>
                            予定：
                            {record.status === "1" ? this.state.section_no + ": 出席 " : record.status === "2" ? this.state.section_no + ": 欠席 " : record.status === "3" ? this.state.section_no + ": Web " : ""}
                            {record.status2 === "1" ? this.state.section2_no + ": 出席 " : record.status2 === "2" ? this.state.section2_no + ": 欠席 " : record.status2 === "3" ? this.state.section2_no + ": Web " : ""}
                            {record.status3 === "1" ? this.state.section3_no + ": 出席 " : record.status3 === "2" ? this.state.section3_no + ": 欠席 " : record.status3 === "3" ? this.state.section3_no + ": Web " : ""}
                            {record.status4 === "1" ? this.state.section4_no + ": 出席 " : record.status4 === "2" ? this.state.section4_no + ": 欠席 " : record.status4 === "3" ? this.state.section4_no + ": Web " : ""}
                            {record.status5 === "1" ? this.state.section5_no + ": 出席 " : record.status5 === "2" ? this.state.section5_no + ": 欠席 " : record.status5 === "3" ? this.state.section5_no + ": Web " : ""}
                            {record.status6 === "1" ? this.state.section6_no + ": 出席 " : record.status6 === "2" ? this.state.section6_no + ": 欠席 " : record.status6 === "3" ? this.state.section6_no + ": Web " : ""}
                            {record.status7 === "1" ? this.state.section7_no + ": 出席 " : record.status7 === "2" ? this.state.section7_no + ": 欠席 " : record.status7 === "3" ? this.state.section7_no + ": Web " : ""}
                            {record.status8 === "1" ? this.state.section8_no + ": 出席 " : record.status8 === "2" ? this.state.section8_no + ": 欠席 " : record.status8 === "3" ? this.state.section8_no + ": Web " : ""}
                            {record.status9 === "1" ? this.state.section9_no + ": 出席 " : record.status9 === "2" ? this.state.section9_no + ": 欠席 " : record.status9 === "3" ? this.state.section9_no + ": Web " : ""}
                            {record.status10 === "1" ? this.state.section10_no + ": 出席" : record.status10 === "2" ? this.state.section10_no + ": 欠席" : record.status10 === "3" ? this.state.section10_no + ": Web" : ""}
                        </span><br />
                        <span style={{whiteSpace: 'nowrap'}}>
                            実績：
                            {record.result_status === "1" ? this.state.section_no + ": 出席 " : record.result_status === "2" ? this.state.section_no + ": 欠席 " : record.result_status === "3" ? this.state.section_no + ": Web " : ""}
                            {record.result_status2 === "1" ? this.state.section2_no + ": 出席 " : record.result_status2 === "2" ? this.state.section2_no + ": 欠席 " : record.result_status2 === "3" ? this.state.section2_no + ": Web " : ""}
                            {record.result_status3 === "1" ? this.state.section3_no + ": 出席 " : record.result_status3 === "2" ? this.state.section3_no + ": 欠席 " : record.result_status3 === "3" ? this.state.section3_no + ": Web " : ""}
                            {record.result_status4 === "1" ? this.state.section4_no + ": 出席 " : record.result_status4 === "2" ? this.state.section4_no + ": 欠席 " : record.result_status4 === "3" ? this.state.section4_no + ": Web " : ""}
                            {record.result_status5 === "1" ? this.state.section5_no + ": 出席 " : record.result_status5 === "2" ? this.state.section5_no + ": 欠席 " : record.result_status5 === "3" ? this.state.section5_no + ": Web " : ""}
                            {record.result_status6 === "1" ? this.state.section6_no + ": 出席 " : record.result_status6 === "2" ? this.state.section6_no + ": 欠席 " : record.result_status6 === "3" ? this.state.section6_no + ": Web " : ""}
                            {record.result_status7 === "1" ? this.state.section7_no + ": 出席 " : record.result_status7 === "2" ? this.state.section7_no + ": 欠席 " : record.result_status7 === "3" ? this.state.section7_no + ": Web " : ""}
                            {record.result_status8 === "1" ? this.state.section8_no + ": 出席 " : record.result_status8 === "2" ? this.state.section8_no + ": 欠席 " : record.result_status8 === "3" ? this.state.section8_no + ": Web " : ""}
                            {record.result_status9 === "1" ? this.state.section9_no + ": 出席 " : record.result_status9 === "2" ? this.state.section9_no + ": 欠席 " : record.result_status9 === "3" ? this.state.section9_no + ": Web " : ""}
                            {record.result_status10 === "1" ? this.state.section10_no + ": 出席" : record.result_status10 === "2" ? this.state.section10_no + ": 欠席" : record.result_status10 === "3" ? this.state.section10_no + ": Web" : ""}
                        </span>
                    </>
                ),
            },
            {
                title: '出欠操作',
                colSpan: 0,
                render: (record) => {
                    const threeDaysAfter = moment().add(3, 'days');
                    const recordDate = moment(this.state.date);
                    const isDateApproch = recordDate.isBefore(threeDaysAfter)
                    const messageInfo = '会議開催3日前になりました。画面からの変更はできませんので、主催者へお問い合わせください。'
                    return (
                        <>
                            {(this.props.auth.user.username === record.key || (record.key.startsWith('ex_') && (this.state.adminFlag === '1' || this.state.adminFlag === '2'))) ?
                                <IconButton tag='editButton' style={{ outline: 'none', padding: '0px' }} onClick={() => { !this.isCreate() && isDateApproch ? alert(messageInfo) : this.handlePsDialogOpen(record, false) }}><EditIcon /></IconButton>
                                : <IconButton tag='editButton' style={{ outline: 'none', padding: '0px' }} disabled><EditIcon /></IconButton>}
                            <br />
                            {(this.state.adminFlag === '1' || this.state.adminFlag === '2') ?
                                <IconButton tag='editButton' style={{ outline: 'none', padding: '0px' }} onClick={() => { this.handlePsDialogOpen(record, true) }}><EditIcon /></IconButton>
                                : <IconButton tag='editButton' style={{ outline: 'none', padding: '0px' }} disabled><EditIcon /></IconButton>
                            }

                        </>
                    );
                    
                },
            },
            {
                title: 'タブレット貸出',
                width: "128px",
                render: (record) => (
                    (this.props.auth.user.username === record.key || (record.key.startsWith('ex_') && (this.state.adminFlag === '1' || this.state.adminFlag === '2'))) ?
                        (record.tablet === "1" ?
                            <div>
                                <span>必要</span>
                                <Button variant="outlined" className="foot-style" size="small" onClick={() => this.handleTabetRental(record, '0')}
                                    style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "10px" }}>{'不要'}</Button>
                            </div> :
                            <div>
                                <span>不要</span>
                                <Button variant="outlined" className="foot-style" size="small" onClick={() => this.handleTabetRental(record, '1')}
                                    style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "10px" }}>{'貸出'}</Button>
                            </div>) :
                        (record.tablet === "1" ?
                            <div>
                                <span>必要</span>
                                <Button variant="outlined" className="foot-style" size="small" style={{ fontWeight: "bold", marginLeft: "10px" }} disabled>{'不要'}</Button>
                            </div> :
                            <div>
                                <span>不要</span>
                                <Button variant="outlined" className="foot-style" size="small" style={{ fontWeight: "bold", marginLeft: "10px" }} disabled>{'貸出'}</Button>
                            </div>)
                ),
            },
        ];
        if (this.state.adminFlag === '1' || this.state.adminFlag === '2') {
            columns.push({
                title: '操作',
                width: "96px",
                render: (record) => (
                    <>
                        {
                            record.key && this.state.conferenceId ?
                            <IconButton style={{ outline: "none", padding: "0px" }} onClick={() => { this.handleSendQRCode(record) }}><Icon>qr_code_2</Icon></IconButton>
                            : <IconButton style={{ outline: "none", padding: "0px" }} disabled><Icon>qr_code_2</Icon></IconButton>
                        }

                        {
                            record.key !== this.props.auth.user.username || (!this.isCreate() && this.props.infoData.info.creator !== record.key) ?
                                <IconButton style={{ outline: "none", padding: "0px" }} onClick={() => { this.handleClickDeleteOpen(record) }}>
                                    <DeleteIcon />
                                </IconButton>
                                : <IconButton style={{ outline: "none", padding: "0px" }} disabled><DeleteIcon /></IconButton>
                        }
                        {
                            record.key.startsWith('ex_') ?
                                <IconButton style={{ outline: "none", padding: "0px" }}
                                    onClick={() => { this.setState({ username: record.key, name: record.name, email: record.email,
                                        department: record.companySector, position: record.position, status: record.status,
                                        status2: record.status2, status3: record.status3, status4: record.status4, status5: record.status5,
                                        status6: record.status6, status7: record.status7, status8: record.status8, status9: record.status9,
                                        status10: record.status10, tablet: record.tablet, externalOpen: true, }) }}>
                                    <SettingsIcon />
                                </IconButton> : <></>
                        }

                    </>
                )
            })
        }
        const addColumns = [
            {
                title: '氏名',
                dataIndex: 'name',
            },
            {
                title: '組合名',
                dataIndex: 'companySector',
                width: '144px'
            },
            {
                title: '役職名',
                dataIndex: 'position',
                width: '144px'
            },
        ];
        const { userInfoKeys } = this.state;
        const rowSelection = {
            userInfoKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <Container style={{ marginTop: "15px" }}>
                    <h4>会議内容</h4>
                    <Divider />
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>会議名称 *</label>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <input disabled={this.state.disabled} className="text-change-size" value={this.state.title} onChange={this.handleChange.bind(this, 'title')}
                                maxLength={150} style={this.state.titleNullCheck ? { border: "1px solid black" } : { border: "1px solid red" }} />
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>日付 *</label>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <DatePicker disabled={this.state.disabled} allowClear={false} locale={localeJa} value={moment(this.state.date, dateFormat)}
                                format={dateFormat} style={{ border: "1px solid black" }} onChange={this.ondateChange} />
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>時刻 *</label>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <TimePicker allowClear={false} locale={localeJa} format={'HH:mm'} minuteStep={15} showNow={false} disabled={this.state.disabled}
                                value={this.state.startTime === undefined ? this.state.startTime : moment(this.state.startTime, 'HH:mm')} onChange={this.onStartTimeChange}
                                style={this.state.timeCheck && this.state.sTimeCheck ? { border: "1px solid black" } : { border: "1px solid red" }} />
                            <label>&nbsp;～&nbsp;</label>
                            <TimePicker allowClear={false} locale={localeJa} showNow={false} format={'HH:mm'} minuteStep={15} disabled={this.state.disabled}
                                value={this.state.endTime === undefined ? this.state.endTime : moment(this.state.endTime, 'HH:mm')} onChange={this.onEndTimeChange}
                                style={this.state.timeCheck && this.state.eTimeCheck ? { border: "1px solid black" } : { border: "1px solid red" }} />
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>会議場所 *</label>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <input disabled={this.state.disabled} className="text-change-size" maxLength={150} onChange={this.handleChange.bind(this, 'room')}
                                value={this.state.room} style={this.state.roomNullCheck ? { border: "1px solid black" } : { border: "1px solid red" }} />
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>Web会議情報：</label>
                        </Grid>
                        <Grid item lg={10} xs={8}>
                            <textarea style={{ color: "black", width: '100%' }} disabled={this.state.disabled} className="textarea-change-size" maxLength={300}
                                value={this.state.webconferenceInfo} onChange={this.handleChange.bind(this, 'webconferenceInfo')} />
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>備考：</label>
                        </Grid>
                        <Grid item lg={10} xs={8}>
                            <textarea style={{ color: "black", width: '100%' }} disabled={this.state.disabled} className="textarea-change-size-remark" maxLength={300}
                                value={this.state.webconferenceRemark} onChange={this.handleChange.bind(this, 'webconferenceRemark')} />
                        </Grid>
                    </Grid>
                    <Grid container className="grid-top">
                        <Grid item lg={2} xs={4}>
                            <label>セッション名：</label>
                        </Grid>
                        <Grid item lg={10} xs={8}>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section_enable !== '1')} className='text-change-size' 
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section}
                                    onChange={this.handleChange.bind(this, 'section')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section2_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section2_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section2_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section2}
                                    onChange={this.handleChange.bind(this, 'section2')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section3_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section3_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section3_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section3}
                                    onChange={this.handleChange.bind(this, 'section3')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section4_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section4_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section4_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section4}
                                    onChange={this.handleChange.bind(this, 'section4')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section5_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section5_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section5_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section5}
                                    onChange={this.handleChange.bind(this, 'section5')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section6_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section6_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section6_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section6}
                                    onChange={this.handleChange.bind(this, 'section6')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section7_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section7_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section7_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section7}
                                    onChange={this.handleChange.bind(this, 'section7')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section8_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section8_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section8_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section8}
                                    onChange={this.handleChange.bind(this, 'section8')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section9_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section9_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section9_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section9}
                                    onChange={this.handleChange.bind(this, 'section9')}/>
                            </div>
                            <div>
                                <Checkbox disabled={this.state.disabled} value='1' checked={this.state.section10_enable === '1'}
                                    onChange={this.handleChange.bind(this, 'section10_enable')}/>
                                <input disabled={this.state.disabled || (this.state.section10_enable !== '1')} className='text-change-size'
                                    style={{ marginLeft: 5, marginBottom: 5 }} value={this.state.section10}
                                    onChange={this.handleChange.bind(this, 'section10')}/>
                            </div>
                        </Grid>
                    </Grid>
                    {
                        this.state.disabled === false ?
                            <Grid container className="grid-top">
                                <Grid item lg={12} xs={12} style={{ textAlign: "right" }}>
                                    <Button variant="outlined" onClick={this.handleOutputAttendee} size="small"
                                        style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "20px" }}>出席者を出力</Button>
                                    <Button variant="outlined" onClick={this.handleClickOpen} size="small"
                                        style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "20px" }}>出席者を追加</Button>
                                    <Button variant="outlined" size="small" onClick={() => { this.setState({ externalOpen: true }) }}
                                        style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "20px" }}>外部出席者を招待</Button>
                                </Grid>
                            </Grid> : <></>
                    }
                    <Grid container className="grid-top" >
                        <Grid item lg={12} xs={12}>
                            <Paper>
                                <Table pagination={false} columns={columns} size="middle" dataSource={this.state.rowsData}
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => { return record }, // 点击表头行
                                        };
                                    }} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Dialog open={this.state.open} onClose={this.handleClose} onEntered={this.onEntered}>
                    <DialogContent>
                        <Grid container className="grid-top">
                            <Grid item lg={10} xs={8}>
                                <input type="text" value={this.state.userInfoSearchKey} onChange={(e) => { this.setState({ userInfoSearchKey: e.target.value }) }} />
                                <Button variant="outlined" onClick={this.searchUser.bind(this)} size="small" style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "20px" }}>検索</Button>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-top">
                            <Grid item lg={12} xs={12}>
                                <Grid item lg={12} xs={12} style={{ textAlign: 'left', marginBottom: 15 }}>
                                    <span>
                                        {this.state.selectedUserInfoNames.length > 0 ? `出席者 :  ${this.state.selectedUserInfoNames}` : ''}
                                    </span>
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <Paper>
                                        <Grid item lg={12} ref={this.tableDom} style={{ backgroundColor: "#007BFF" }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td key={1} style={{ color: "white", fontWeight: "900", padding: "12px 8px ", textAlign: "left", }}>
                                                            <label style={{ height: "13.4px", paddingLeft: '60px', width: this.state.width }}>氏名/配布リスト名</label>
                                                        </td>
                                                        <td key={3} style={{ color: "white", fontWeight: "900", padding: "12px 8px", textAlign: "left", }}>
                                                            <label style={{ height: "13.4px", width: "148px" }}>組合名</label>
                                                        </td>
                                                        <td key={2} style={{ color: "white", fontWeight: "900", padding: "12px 8px", textAlign: "left", }}>
                                                            <label style={{ height: "13.4px", width: "148px" }}>役職名</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <div onScrollCapture={() => this.onScrollHandle()} style={{ height: '190px', overflowY: 'scroll' }}
                                                ref={c => { this.scrollRef = c; }}>
                                                <Table showHeader={false} rowSelection={rowSelection} pagination={false} columns={addColumns} size="middle"
                                                    dataSource={this.state.addTableRows} rowClassName={(record, index) => index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'} />
                                            </div>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        <Button color="primary" onClick={this.handleAdd} style={{ outline: "none" }} size="small">追加</Button>
                        <Button onClick={this.handleClose} color="primary" style={{ outline: "none" }} size="small">戻る</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.psOpen} onClose={this.handlePsDialogClose}>
                    <DialogContent style={{ width: "450px" }}>
                        <Grid container style={{ marginBottom: "15px" }}>
                            {this.state.section_enable !=='1' && this.state.section2_enable !=='1' && this.state.section3_enable !=='1' && 
                                this.state.section4_enable !=='1' && this.state.section5_enable !=='1' && this.state.section6_enable !=='1' && 
                                this.state.section7_enable !=='1' && this.state.section8_enable !=='1' && this.state.section9_enable !=='1' && 
                                this.state.section10_enable !=='1' && 
                                <Grid item xs={12}><label>対象セッションが存在しません。</label></Grid>
                            }
                            {this.state.section_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section ? (this.state.section + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status: event.target.value, status_err: false }) }} value={this.state.status}
                                            className={this.state.status_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section2_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section2 ? (this.state.section2 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status2: event.target.value, status2_err: false }) }} value={this.state.status2}
                                            className={this.state.status2_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section3_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section3 ? (this.state.section3 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status3: event.target.value, status3_err: false }) }} value={this.state.status3}
                                            className={this.state.status3_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section4_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section4 ? (this.state.section4 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status4: event.target.value, status4_err: false }) }} value={this.state.status4}
                                            className={this.state.status4_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section5_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section5 ? (this.state.section5 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status5: event.target.value, status5_err: false }) }} value={this.state.status5}
                                            className={this.state.status5_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section6_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section6 ? (this.state.section6 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status6: event.target.value, status6_err: false }) }} value={this.state.status6}
                                            className={this.state.status6_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section7_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section7 ? (this.state.section7 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status7: event.target.value, status7_err: false }) }} value={this.state.status7}
                                            className={this.state.status7_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"3"}>Web</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section8_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section8 ? (this.state.section8 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status8: event.target.value, status8_err: false }) }} value={this.state.status8}
                                            className={this.state.status8_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section9_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section9 ? (this.state.section9 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status9: event.target.value, status9_err: false }) }} value={this.state.status9}
                                            className={this.state.status9_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                            {this.state.section10_enable === '1' &&
                                <>
                                    <Grid item xs={5}><label>{this.state.section10 ? (this.state.section10 + '：') : ''}</label></Grid>
                                    <Grid item xs={7}>
                                        <Radio.Group onChange={(event) => { this.setState({ status10: event.target.value, status10_err: false }) }} value={this.state.status10}
                                            className={this.state.status10_err ? 'fail-radio-group' : ''}>
                                            <Radio value={"1"}>出席</Radio>
                                            <Radio value={"2"}>欠席</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </>}
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        <Button color="primary" style={{ outline: "none" }} size="small" onClick={this.handlePsDialogConfirm}>確定</Button>
                        <Button color="primary" style={{ outline: "none" }} size="small" onClick={this.handlePsDialogClose}>戻る</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.deleteOpen} onClose={this.handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className="dialog-title-style"></DialogTitle>
                    <DialogContent>
                        <DialogContentText>削除しますか？</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        <Button color="secondary" onClick={() => this.deleteTableData()} style={{ outline: "none" }} size="small">削除</Button>
                        <Button onClick={this.handleDeleteClose} color="primary" style={{ outline: "none" }} size="small">キャンセル</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.externalOpen} onClose={() => { this.setState({ externalOpen: false, ...clearExternalState, ...clearStatus, }) }}>
                    <DialogContent style={{ width: "600px" }}>
                        <Grid container>
                            <Grid item xs={3}><label>氏名 *</label></Grid>
                            <Grid item xs={9}>
                                <input value={this.state.name} maxLength={50} onChange={this.handleChange.bind(this, 'name')}
                                    style={this.state.nameNullCheck ? { border: "1px solid black", width: "260px" } : { border: "1px solid red", width: "260px" }} />
                            </Grid>
                            <Grid item xs={3}><label>メール *</label></Grid>
                            <Grid item xs={9}>
                                <input value={this.state.email} maxLength={128} onChange={this.handleChange.bind(this, 'email')}
                                    style={this.state.emailNullCheck && this.state.mailCheck ? { border: "1px solid black", width: "260px" } : { border: "1px solid red", width: "260px" }} />
                            </Grid>
                            <Grid item xs={3}><label>組合名</label></Grid>
                            <Grid item xs={9}><input value={this.state.department} maxLength={128} onChange={this.handleChange.bind(this, 'department')} style={{ border: "1px solid black", width: "260px" }} /></Grid>
                            <Grid item xs={3}><label>役職名</label></Grid>
                            <Grid item xs={9}><input value={this.state.position} maxLength={128} onChange={this.handleChange.bind(this, 'position')} style={{ border: "1px solid black", width: "260px" }} /></Grid>
                            <Grid item xs={3}><label>タブレット貸出</label></Grid>
                            <Grid item xs={9}>
                                <Radio.Group onChange={(event) => { this.setState({ tablet: event.target.value }) }} value={this.state.tablet}>
                                    <Radio value={'1'}>必要</Radio>
                                    <Radio value={'0'}>不要</Radio>
                                </Radio.Group>
                            </Grid>
                            <Grid item xs={3}><label>出欠予定</label></Grid>
                            <Grid item xs={9}>
                                <Grid container>
                                    {this.state.section_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section ? (this.state.section + '：') : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status: event.target.value }) }} value={this.state.status}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section2_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section2 ? (this.state.section2 + '：') : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status2: event.target.value }) }} value={this.state.status2}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section3_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section3 ? this.state.section3 + '：' : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status3: event.target.value }) }} value={this.state.status3}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section4_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section4 ? this.state.section4 + '：' : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status4: event.target.value }) }} value={this.state.status4}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section5_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section5 ? this.state.section5 + '：' : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status5: event.target.value }) }} value={this.state.status5}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section6_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section6 ? (this.state.section6 + '：') : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status6: event.target.value }) }} value={this.state.status6}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section7_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section7 ? (this.state.section7 + '：') : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status7: event.target.value }) }} value={this.state.status7}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"3"}>Web</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section8_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section8 ? this.state.section8 + '：' : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status8: event.target.value }) }} value={this.state.status8}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section9_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section9 ? this.state.section9 + '：' : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status9: event.target.value }) }} value={this.state.status9}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                    {this.state.section10_enable === '1' &&
                                        <>
                                            <Grid item xs={4}><label>{this.state.section10 ? this.state.section10 + '：' : ''}</label></Grid>
                                            <Grid item xs={8}>
                                                <Radio.Group onChange={(event) => { this.setState({ status10: event.target.value }) }} value={this.state.status10}>
                                                    <Radio value={"1"}>出席</Radio>
                                                    <Radio value={"2"}>欠席</Radio>
                                                </Radio.Group>
                                            </Grid>
                                        </>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        <Button color="primary" style={{ outline: "none" }} size="small" onClick={this.handleAddExternalUser}>確定</Button>
                        <Button color="primary" style={{ outline: "none" }} size="small"
                            onClick={() => { this.setState({ externalOpen: false, ...clearExternalState, ...clearStatus, }) }}>戻る</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.messageDialogOpen} onClose={() => { this.setState({ ...clearMessageDialogState, }) }}
                    aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className="dialog-title-style"></DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.state.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        <Button color="primary" style={{ outline: "none" }} size="small" onClick={() => { this.setState({ ...clearMessageDialogState }) }}>確認</Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        infoData: state.infoData,
        userData: state.userData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMeeting: (infoData) => dispatch(setMeeting(infoData)),
        setMaterial: (materials) => dispatch(setMaterial(materials)),
        setUserMaterial: (materials) => dispatch(setUserMaterial(materials)),
        setLoading: (loading) => dispatch(setLoading(loading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Kaiginaiyou)