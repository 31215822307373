import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './auth/authReducer'
import meetingReducer from './mettingInfo/meetingReducer'
import loadingReducer from './loading/loadingReducer'
import calendarListReducer from './calendarList/calendarListReducer'

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    infoData: meetingReducer,
    loadingData: loadingReducer,
    calendarListData: calendarListReducer
    //TODO
})

export default rootReducer