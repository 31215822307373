import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Table } from 'antd';
import { API } from 'aws-amplify'
import { setAuth, setLoading } from '../../redux'
import { connect } from 'react-redux'
import { Radio } from 'antd';
import {
    Container,
    Grid,
    Paper,
    Divider,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import 'antd/dist/antd.css';
import '../../style/conference.css'
import '../../style/calendar.css'

class UserKanri extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // ダイアログの状態
            open: false,
            openChangeTable: false,
            rowsData: [],
            key: '',
            name: '',
            companySector: '',
            mail: '',
            authority: '',
            status: "0",
            record: '',
            paginationToken: '',
            isbottom: false,
            width: '',

            nameNullCheck: true,
            mailNullCheck: true,
            mailCheck: true
        }

        this.getUserInfo = this.getUserInfo.bind(this)
        this.userAdd = this.userAdd.bind(this)
        this.userUpdate = this.userUpdate.bind(this)
        this.getNextUserInfo = this.getNextUserInfo.bind(this)

        this.userNameValue = React.createRef();
        this.scrollRef = React.createRef();
        this.grid = React.createRef();
    }

    // 画面初期化
    componentDidMount() {
        let adminFlag = 'custom:adminFlag' in this.props.auth.user.attributes ? this.props.auth.user.attributes['custom:adminFlag'] : '0'
        if (adminFlag !== '1' && adminFlag !== '2') {
            this.props.history.push("/calendarSchedule")
        } else {
            this.getUserInfo("");
            this.setState({
                width: this.grid.current.clientWidth - 669
                })
        }
    }

    componentWillUnmount = () => {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    async getUserInfo(nameValue) {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "getUsers",
                "filter": {
                    "name": "name",
                    "value": nameValue
                }
            },
            response: true,
        }).then((response) => {
            let data = response.data[0].users
            let datas = []
            for (let i = 0; i < data.length; i++) {
                datas.push(
                    {
                        key: data[i].username,
                        name: data[i].name,
                        authority: data[i].level,
                        companySector: data[i].department,
                        mail: data[i].email,
                        position: data[i].position
                    }
                )
            }
            this.setState({
                rowsData: datas,
                paginationToken: response.data[0].paginationToken
            }, () => {
                if (this.state.paginationToken === undefined) {
                    this.setState({
                        isBottom: true
                    })
                }
            });
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    async getNextUserInfo(paginationToken) {
        this.props.setLoading(true)
        if (this.state.isBottom) {
            this.props.setLoading(false)
            return
        }
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "getUsers",
                "paginationToken": paginationToken
            },
            response: true,
        }).then((response) => {
            let data = response.data[0].users
            let datas = []
            for (let i = 0; i < data.length; i++) {
                datas.push(
                    {
                        key: data[i].username,
                        name: data[i].name,
                        authority: data[i].level,
                        companySector: data[i].department,
                        mail: data[i].email,
                        position: data[i].position
                    }
                )
            }
            datas = this.state.rowsData.concat(datas)
            this.setState({
                rowsData: datas,
                paginationToken: response.data[0].paginationToken
            }, () => {
                if (this.state.paginationToken === undefined) {
                    this.setState({
                        isBottom: true
                    })
                }
            });
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    handleClickOpen = (record) => {
        this.setState({
            open: true,
            record: record
        })
    };

    handleClose = (type) => {
        if (type === "delete") {
            this.deleteTableData();
        } else if (type === "return") {
            this.setState({
                open: false
            })
        }
    };

    async deleteTableData() {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "deleteUser",
                "operator": this.props.auth.user.username,
                "username": this.state.record.key
            },
            response: true,
        }).then((response) => {
            this.setState({
                open: false
            })
            window.location.reload();
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    handleOpenChange = (record) => {
        if (record !== "add") {
            this.setState({
                name: record.name,
                mail: record.mail,
                companySector: record.companySector,
                position: record.position,
                authority: record.authority,
                key: record.key,
                status: "1",
                mailCheck:true,
                mailNullCheck:true,
                nameNullCheck:true,
            })
        } else {
            this.setState({
                name: "",
                mail: "",
                companySector: "",
                position: "",
                authority: "0",
                key: "",
                status: "0",
                mailCheck:true,
                mailNullCheck:true,
                nameNullCheck:true,
            })
        }
        this.setState({
            openChangeTable: true,
        })
    };

    handleCloseChange = () => {
        this.setState({
            openChangeTable: false
        })
    };

    handleChange = (name, e) => {
        const { value } = e.target;
        this.setState({
            [name]: value
        }, () => {
            if (name === "name" && this.state.name !== "") {
                this.setState({
                    nameNullCheck: true
                })
            } else if (name === "mail" && this.state.mail !== "") {
                this.setState({
                    mailNullCheck: true,
                    mailCheck: true
                })
            } else {
                //
            }
        });
    }

    handleEmail = (value) => {
        const mail_regexp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        if (value.match(mail_regexp) == null) {
            this.setState({
                mailCheck: false
            })
            alert("正しいメールアドレスを入力してください")
            return false
        }
        this.setState({
            mailCheck: true
        })
        return true
    }

    userChange = () => {
        // null check
        if (this.state.name === "" || this.state.mail === "") {
            if (this.state.name === "") {
                this.setState({
                    nameNullCheck: false
                })
            }

            if (this.state.mail === "") {
                this.setState({
                    mailNullCheck: false
                })
            }
            alert("必須項目が記入されていない")
            return
        }

        // mail check
        if (!this.handleEmail(this.state.mail)) {
            return
        }

        if (this.state.status === "0") {
            this.userAdd();
        } else {
            this.userUpdate();
        }

    }

    async userAdd() {
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "createUser",
                "operator": this.props.auth.user.username,
                "name": this.state.name,
                "level": this.state.authority,
                "department": this.state.companySector,
                "email": this.state.mail,
                "position": this.state.position
            },
            response: true,
        }).then((response) => {
            if (response.data[0].error !== undefined) {
                this.setState({
                    mailCheck: false,
                })
                alert("現在メールアドレスが存在しておりますので、他のメールアドレスをご利用ください")
                return
            }

            this.setState({
                openChangeTable: false,
            }, () => {
                this.getUserInfo();
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    async userUpdate() {
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "updateUser",
                "operator": this.props.auth.user.username,
                "username": this.state.key,
                "name": this.state.name,
                "level": this.state.authority,
                "department": this.state.companySector,
                "email": this.state.mail,
                "position": this.state.position
            },
            response: true,
        }).then((response) => {
            if (response.data[0].error !== undefined) {
                this.setState({
                    mailCheck: false,
                })
                alert("現在メールアドレスが存在しておりますので、他のメールアドレスをご利用ください")
                return
            }

            this.setState({
                openChangeTable: false,
            }, () => {
                this.getUserInfo();
            })


        }).catch((error) => {
            console.log(error)
        })
    }

    // 検索ボタンで出席者情報を取得
    serachUser() {
        this.getUserInfo(this.userNameValue.current.value)
    }

    returnSchedule = () => {
        this.props.history.push("/calendarSchedule")
    }

    handleScroll = (e) => {
        console.log(e)
    }

    onRadioChange = e => {
        this.setState({
            authority: e.target.value,
        });
    };

    onScrollHandle = () => {
        // eslint-disable-next-line
        const scrollTop = this.scrollRef.scrollTop;
        // eslint-disable-next-line
        const clientHeight = this.scrollRef.clientHeight;
        // eslint-disable-next-line
        const scrollHeight = this.scrollRef.scrollHeight;
        const isBottom = Math.ceil(scrollTop) + clientHeight === scrollHeight;
        if (isBottom) {
            this.getNextUserInfo(this.state.paginationToken)
        }
    }

    async handleOutputAllUsers() {
        this.props.setLoading(true)
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                "operation": "outputAllUsers",
                "operator": this.props.auth.user.username,
            },
            response: true,
        }).then((response) => {
            const byteCharacters = atob(response.data[0].excel);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const filename="ユーザーリスト.xlsx"
            if (typeof window.navigator.msSaveBlob != 'undefined') {
                window.navigator.msSaveBlob(blob, filename)
            } else {
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a')
                link.href = objectUrl
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }).catch((error) => {
            console.log(error)
        })
        this.props.setLoading(false)
    }

    render() {
        const columns = [
            {
                title: '氏名',
                dataIndex: 'name',
                width: '150px',
            },
            {
                title: 'メール',
                dataIndex: 'mail',
                width: '250px',
                render: (text) => (
                    <div style={{display: 'block', width: '234px'}}>{text}</div>)
            },
            {
                title: '組合名',
                dataIndex: 'companySector',
                width: "148px",
            },
            {
                title: '役職名',
                dataIndex: 'position',
                width: "148px",
            },
            {
                title: '権限',
                dataIndex: 'authority',
                width: "105px",
                render: (text, record, index) => (
                    <div>
                        {text === "1" ? "管理者" : text === "2" ? "開催者" : "一般ユーザー"}
                    </div>
                ),
            },
            {
                title: 'アクション',
                dataIndex: 'method',
                render: (text, record, index) => (
                    <div>
                        <IconButton
                            style={{ outline: "none", padding: "0px" }}
                            onClick={() => this.handleClickOpen(record)}><DeleteIcon /></IconButton>
                        <IconButton
                            onClick={() => this.handleOpenChange(record)}
                            style={{ outline: "none", padding: "0px", marginLeft: "20px" }}><EditIcon /></IconButton>
                    </div>
                ),
            },
        ];
        return (
            <Paper elevation={0} className="body-init" style={{width: "1000px" }}>
                <Container style={{ height: "500px" }}>
                    <Grid style={{ margin: "20px auto" }} item lg={12} xs={12}>
                        <h4 style={{ textAlign: "left" }}>社内ユーザ管理</h4>
                        <Divider style={{ marginBottom: "20px" }} />
                        <Grid container className="grid-top" item lg={12} xs={12}>
                            <Grid item xs={4} style={{ textAlign: "left" }}>
                                <input type="text" ref={this.userNameValue} />
                                <Button
                                    variant="outlined"
                                    style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "20px" }}
                                    onClick={this.serachUser.bind(this)}
                                    size="small" >検索</Button>
                            </Grid>
                            <Grid item xs={8} style={{ textAlign: "right" }}>
                                <Button
                                    variant="outlined"
                                    style={{ outline: "none", color: "#1976D2", fontWeight: "bold" }}
                                    onClick={() => this.handleOutputAllUsers()} 
                                    size="small" >全てユーザーを出力</Button>
                                <Button
                                    variant="outlined"
                                    style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "10px"}}
                                    onClick={() => this.handleOpenChange("add")}
                                    size="small" >追加</Button>
                                <Button
                                    variant="outlined"
                                    style={{ outline: "none", color: "#1976D2", fontWeight: "bold", marginLeft: "10px" }}
                                    onClick={this.returnSchedule}
                                    size="small" >戻る</Button>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-top">
                            <Grid item xs={12}>
                                <Paper>
                                    <Grid item ref={this.grid} style={{ backgroundColor: "#007BFF" }}>
                                        <table>
                                            <tbody>
                                                <tr >
                                                    <td
                                                        key={1}
                                                        style={{
                                                            color: "white",
                                                            fontWeight: "900",
                                                            padding: "12px 8px",
                                                            textAlign: "left",
                                                        }}>
                                                        <label style={{ height: "13.4px", width: "134px" }}>氏名</label></td>
                                                    <td
                                                        key={2}
                                                        style={{
                                                            color: "white",
                                                            fontWeight: "900",
                                                            padding: "12px 8px",
                                                            textAlign: "left",
                                                        }}><label style={{ height: "13.4px", width: "234px" }}>メール</label></td>
                                                    <td
                                                        key={3}
                                                        style={{
                                                            color: "white",
                                                            fontWeight: "900",
                                                            padding: "12px 8px",
                                                            textAlign: "left",

                                                        }}><label style={{ height: "13.4px", width: "132px" }}>組合名</label></td>
                                                    <td
                                                        key={4}
                                                        style={{
                                                            color: "white",
                                                            fontWeight: "900",
                                                            padding: "12px 8px",
                                                            textAlign: "left",
                                                        }}><label style={{ height: "13.4px", width: "132px" }}>役職名</label></td>
                                                    <td
                                                        key={5}
                                                        style={{
                                                            color: "white",
                                                            fontWeight: "900",
                                                            padding: "12px 8px",
                                                            textAlign: "left",
                                                        }}><label style={{ height: "13.4px", width: "89px" }}>権限</label></td>
                                                    <td
                                                        key={6}
                                                        style={{
                                                            color: "white",
                                                            fontWeight: "900",
                                                            padding: "12px 8px",
                                                            textAlign: "left",
                                                        }}><label style={{ height: "13.4px", width: this.state.width }}>アクション</label></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item lg={12}>
                                        <div
                                            onScrollCapture={() => this.onScrollHandle()}
                                            style={{ height: '300.4px', overflowY: 'scroll' }}
                                            ref={c => {
                                                this.scrollRef = c;
                                            }}
                                        >
                                            <Table
                                                showHeader={false}
                                                pagination={false}
                                                columns={columns}
                                                size="middle"
                                                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-odd-color' : 'table-row-even-color'}
                                                dataSource={this.state.rowsData}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: () => { return record },
                                                    };
                                                }} />
                                        </div>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Dialog
                            open={this.state.open}
                        >
                            <DialogTitle className="dialog-title-style"></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    削除しますか？
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                <Button
                                    color="secondary"
                                    onClick={() => this.handleClose("delete")}
                                    style={{ outline: "none" }}
                                    size="small">
                                    削除
                                </Button>
                                <Button
                                    onClick={() => this.handleClose("return")}
                                    color="primary"
                                    style={{ outline: "none" }}
                                    size="small">
                                    キャンセル
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Grid>
                        <Dialog
                            open={this.state.openChangeTable}
                            onClose={this.handleCloseChange}

                        >
                            <DialogContent style={{ width: "460px" }}>
                                <Grid container style={{ marginBottom: "15px" }}>
                                    <Grid item lg={3}>
                                        <label>氏名 *</label>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <input
                                            value={this.state.name}
                                            style={this.state.nameNullCheck ? { border: "1px solid black", width: "260px"} : { border: "1px solid red", width: "260px"}}
                                            maxLength={50}
                                            onChange={this.handleChange.bind(this, 'name')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: "15px" }}>
                                    <Grid item lg={3}>
                                        <label>メール *</label>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <input type="text"
                                            value={this.state.mail}
                                            style={this.state.mailNullCheck && this.state.mailCheck ? { border: "1px solid black", width: "260px"} : { border: "1px solid red", width: "260px"}}
                                            maxLength={128}
                                            onChange={this.handleChange.bind(this, 'mail')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: "15px" }}>
                                    <Grid item lg={3}>
                                        <label>組合名：</label>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <input type="text"
                                            value={this.state.companySector}
                                            style={{border: "1px solid black", width: "260px"}}
                                            maxLength={128}
                                            onChange={this.handleChange.bind(this, 'companySector')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: "15px" }}>
                                    <Grid item lg={3}>
                                        <label>役職名：</label>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <input type="text"
                                            value={this.state.position}
                                            style={{border: "1px solid black", width: "260px"}}
                                            maxLength={128}
                                            onChange={this.handleChange.bind(this, 'position')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: "15px" }}>
                                    <Grid item lg={3}>
                                        <label>権限：</label>
                                    </Grid>
                                    <Grid item lg={9}>
                                        <Radio.Group onChange={this.onRadioChange} value={this.state.authority}>
                                            <Radio value={"1"}>管理者</Radio>
                                            <Radio value={"2"}>開催者</Radio>
                                            <Radio value={"0"}>一般ユーザー</Radio>
                                        </Radio.Group>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                                <Button
                                    color="primary"
                                    onClick={this.userChange}
                                    style={{ outline: "none" }}
                                    size="small">
                                    {this.state.status === "0" ? "追加" : "更新"}
                                </Button>
                                <Button
                                    onClick={this.handleCloseChange}
                                    color="primary"
                                    style={{ outline: "none" }}
                                    size="small">
                                    キャンセル
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Container>
            </Paper >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.userData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (authData, userData) => dispatch(setAuth(authData, userData)),
        setLoading: (loading) => dispatch(setLoading(loading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserKanri)