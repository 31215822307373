import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { setAuth } from './redux'
import AppContainer from './components/AppContainer';
import CusAmplifyAuthenticator from './components/CusAmplifyAuthenticator';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './style/conference.css'

function App({ auth, setAuth }) {

    useEffect(() => {
        return onAuthUIStateChange((authData, userData) => {
            setAuth(authData, userData)
        });
    }, [setAuth])

    return (
        <div className="App">
            {auth.authState === AuthState.SignedIn && auth.user ?
                (<AppContainer />) : (<CusAmplifyAuthenticator />)}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (authData, userData) => dispatch(setAuth(authData, userData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
